import { DatePicker, DatePickerProps, Form } from "antd";

type InputProps = {
  name: string;
  label: string;
  required?: boolean;
  message?: string;
  type?: any;
  onChange?: any;
  disabled?:boolean;
};

const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log("date", date, dateString);
};

const FormDatePicker: React.FC<InputProps> = ({
  name,
  label,
  required,
  message,
  disabled=false
}: InputProps) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: message }]}
    >
      <DatePicker onChange={onChange} style={{ width: "100%" }} disabled={disabled} />
    </Form.Item>
  );
};

export { FormDatePicker };
