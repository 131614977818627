import { Button, Flex, message, Upload, UploadProps } from "antd";
import { Table } from "ant-table-extensions";
import { UploadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { columns, IImportTblData, mockData } from "../interfaces/Iimport";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { fetchImportEmp, requestImportEmp } from "../middleware/fetcher/importEmp";
import isEmpty from "is-empty";
import { CSVLink } from "react-csv";

const ImportEmp: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [isNotHaveRequest, setIsNotHaveRequest] = useState(true);
  const [exportTblData, setExportTblData] = useState<Array<IImportTblData>>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [reqID, setReqID] = useState(null);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    setMenuSelectedKey("3_5");
    fetchAPI();
    importTblData();
  }, []);

  const permImport = useMemo(() => permissions?.import?.permission_action || {}, [permissions]);
  const onClickImport = async (id?: number | null) => {
    try {
      if (isEmpty(id)) {
        message.error("อัพโหลดไฟล์ล้มเหลว");
      } else {
        await requestImportEmp({
          id: id,
        });
        setReqID(null);
        setIsNotHaveRequest(true);
        setFileList([]);
        message.success("อัพโหลดไฟล์สำเร็จ");
        await fetchAPI();
      }
    } catch (error) {
      message.error("อัพโหลดไฟล์ล้มเหลว");
    }
  };

  const fetchAPI = async () => {
    const historyTemp = await fetchImportEmp();
    if (!isEmpty(historyTemp)) {
      setHistoryList(historyTemp.data?.list);
    }
  };

  const importTblData = async () => {
    const historyTemp = await fetchImportEmp();
    let tblData: IImportTblData[] = [];
    let importTblData: IImportTblData;

    historyTemp.data?.list.map((item: any) => {
      importTblData = {
        รหัสพนักงาน: item.imp_emp_code,
        ชื่อ_นามสกุล: item.imp_emp_first_name + " " + item.imp_emp_last_name,
        รหัสแผนก: item.imp_dept_code,
        เงินเดือน: item.imp_emp_salary,
        สถานะ: item.imp_emp_status,
      };
      tblData.push(importTblData);
    });
    setExportTblData(tblData);
  };

  return (
    <>
      <Flex align="center" justify="flex-end">
        <Flex gap="middle">
          <Upload
            name="file"
            fileList={fileList}
            action={`${process.env.REACT_APP_APIURL}/ipEmp/upload`}
            multiple={false}
            headers={{
              authorization: `Bearer ${Cookies.get("access_token")}`, // ใส่ token ที่นี่
            }}
            onChange={(info: any) => {
              if (info?.status === "error") {
                message.error(`${info.name} file upload failed.`);
              } else if (info.file.status === "uploading") {
                setFileList(info.fileList);
              } else if (info.file.status === "done") {
                let responseId = info.file.response?.data?.id;
                setReqID(responseId);
                setIsNotHaveRequest(false);
                setFileList([]);
                message.success(`${info.file.name} file uploaded successfully`);
              }
            }}
            onRemove={(file) => {
              setIsNotHaveRequest(true);
              setFileList([]);
              // ที่นี่คุณสามารถทำงานเพิ่มเติม เช่น ส่งคำขอลบไฟล์จากเซิร์ฟเวอร์
            }}
          >
            {permImport?.add && (
              <Button icon={<UploadOutlined />} disabled={!isNotHaveRequest}>
                Click to Upload
              </Button>
            )}
          </Upload>
          <Button type="primary" onClick={() => onClickImport(reqID)} disabled={isNotHaveRequest}>
            <CloudUploadOutlined />
            Start Import
          </Button>
        </Flex>
      </Flex>
      <br />
      <CSVLink filename={"ImportEmp.csv"} data={exportTblData} className="btn btn-primary" style={{ position: "absolute" }}>
        บันทึกเป็น CSV
      </CSVLink>
      <Table columns={columns()} dataSource={historyList}  searchable />
    </>
  );
};

export default ImportEmp;
