import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchProject = (params?: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project/list/${params}`,
    });
};

export const fetchCreateProject = (data: IObject) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project/create`,
        data,
    });
};

export const fetchProjectDelete = (params: string) => {
    return requestHandler({
        method: "delete",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project/delete/${params}`,
    });
};

export const fetchProjectEdit = (params: string, data: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project/edit/${params}`,
        data,
    });
};
