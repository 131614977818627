import React from 'react';
import { Layout } from 'antd';
import { AppBreadcrumb } from './AppBreadcrumb';
const { Content } = Layout;
type props = {
    colorBgContainer: any;
    borderRadiusLG: any;
    children: any;
}

const AppContent: React.FC<props> = ({ colorBgContainer, borderRadiusLG, children }) => {

    return (
        <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            {/* <AppBreadcrumb /> */}
            {children}
        </Content>
    )
}

export { AppContent }