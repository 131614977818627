import { Table } from "ant-table-extensions";
import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Drawer,
  Flex,
  Form,
  message,
  Modal,
  Row,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { FormDatePicker } from "../_start/layout/components/form-compoment/FormDatePicker";
import {
  SearchOutlined,
  PlusOutlined,
  CaretRightOutlined,
  ExportOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  columns,
  ITimeSheet,
  mockProject,
  mockSheetData,
  mockTask,
  TimeSheetCreate,
  TimeSheetFilter,
  TimeSheetForm,
  TimeSheetInit,
  TimeSheetTblData,
  TimeSheetUpdate,
} from "../interfaces/ITimeSheet";
import { FormTimePicker } from "../_start/layout/components/form-compoment/FormTimePicker";
import { DropDown } from "../interfaces/ICenter";
import {
  fetchProjectSelect,
  fetchTaskSelect,
  fetchTimeSheetCreate,
  fetchTimesheetFilter,
  fetchTimeSheetInit,
  fetchTimeSheetPut,
  fetchTimeSheetRaw,
} from "../middleware/fetcher/timesheet";
import dayjs from "dayjs";
import { IShift } from "../interfaces/IShift";
import { fetchShiftEmp } from "../middleware/fetcher/shift";
import { CSVLink } from "react-csv";
const { confirm } = Modal;

const TimeSheet: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [timeSheetData, setTimeSheetData] = useState<Array<TimeSheetInit>>([]);
  const [exportTblData, setExportTblData] = useState<Array<TimeSheetTblData>>([]);
  const [projectSelect, setProjectSelect] = useState<Array<DropDown>>();
  const [taskSelect, setTaskSelect] = useState<Array<DropDown>>();
  const [formTaskSelect, setFormTaskSelect] = useState<Array<DropDown>>();
  const [shiftEmp, setShiftEmp] = useState<IShift>();
  const [event, setEvent] = useState("");
  const [id, setId] = useState("");
  const { setMenuSelectedKey, permissions, isMobile } = useAppDataContext();
  const [disable, setDisable] = useState<boolean>();

  const permTimeSheet = useMemo(
    () => permissions?.timesheet?.permission_action || {},
    [permissions]
  );

  useEffect(() => {
    setMenuSelectedKey("1_2");
    fetchAPIprojectSelectDropDown();
    fetchAPITimeSheetInit();
    fetchAPIShiftEmp();
  }, []);

  const fetchAPITimeSheetInit = async () => {
    const rsTimeSheetInit: any = await fetchTimeSheetInit();
    setTimeSheetData(rsTimeSheetInit.data);

    let tblData: TimeSheetTblData[] = [];
    let TimeSheetTblData: TimeSheetTblData;

    rsTimeSheetInit.data.map((item: any) => {
      TimeSheetTblData = {
        โครงการ: item.project.project_name,
        รายละเอียดโครงการ: item.project.description,
        งาน: item.task.task_name,
        รายละเอียดงาน: item.task.description,
        วันที่: item.date,
        เวลา: item.time,
        ชั่วโมง: item.hour,
      };
      tblData.push(TimeSheetTblData);
    });
    setExportTblData(tblData);
  };

  const fetchAPIprojectSelectDropDown = async () => {
    const resProject: any = await fetchProjectSelect();
    setProjectSelect(resProject.data);
  };

  const [formSearch] = Form.useForm();
  const [form] = Form.useForm();
  const onFinish = () => {
    const dt: TimeSheetForm = { ...form.getFieldsValue() };
    let title = "";
    if (event == "add") {
      title = "ต้องการบันทึกข้อมูลใช่หรือไม่?";
    } else {
      title = "ต้องการบันทึกข้อมูลใช่หรือไม่?";
    }
    confirm({
      title: `${title} ${dt.form_task}?`,
      async onOk() {
        try {
          const getDate = dayjs(dt.form_date).format("YYYY-MM-DD");
          const getStart = dayjs(dt.form_start).format("HH:mm");
          const getEnd = dayjs(dt.form_end).format("HH:mm");
          if (event == "add") {
            const tsCreate: TimeSheetCreate = {
              project: dt.form_project,
              task: dt.form_task,
              start: dayjs(`${getDate}T${getStart}`).toISOString(),
              end: dayjs(`${getDate}T${getEnd}`).toISOString(),
              date: dt.form_date,
            };

            await fetchTimeSheetCreate(tsCreate);
          } else {
            const tsUpdate: TimeSheetUpdate = {
              id: id,
              date: dt.form_date,
              start: dayjs(`${getDate}T${getStart}`).toISOString(),
              end: dayjs(`${getDate}T${getEnd}`).toISOString(),
            };

            await fetchTimeSheetPut(tsUpdate);
          }

          const rsTimeSheetInit: any = await fetchTimeSheetInit();
          setTimeSheetData(rsTimeSheetInit.data);

          setOpen(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const onDateSelect = (e: any) => {
    //console.log(e)
  };

  const showDrawer = () => {
    setEvent("add");
    setOpen(true);
    setDisable(false);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const EditProject = async (e: string) => {
    setEvent("edit");
    setDisable(true);
    const rsTimeSheetRaw = await fetchTimeSheetRaw(e);
    const dataCreate: TimeSheetForm = {
      form_project: rsTimeSheetRaw.data.project,
      form_task: rsTimeSheetRaw.data.task,
      form_start: dayjs(rsTimeSheetRaw.data.start, "YYYY/MM/DD HH:mm:ss").add(7, "hour"),
      form_end: dayjs(rsTimeSheetRaw.data.end, "YYYY/MM/DD HH:mm:ss").add(7, "hour"),
      form_date: dayjs(rsTimeSheetRaw.data.date, "YYYY/MM/DD"),
    };
    setId(e);
    form.setFieldsValue(dataCreate);
    setOpen(true);
  };

  const DeleteRecord = (e: string) => {
    message.success(`ลบราย Project ${e} แล้ว`);
  };

  const selectChange = (value: string) => {
    //console.log(`selected ${value}`);
  };

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const onSearch = async () => {
    const dt: TimeSheetFilter = { ...formSearch.getFieldsValue() };
    const filter: TimeSheetFilter = {
      project: dt.project,
      task: dt.task,
      start: dt.start,
      end: dt.end,
    };
    const resProject: any = await fetchTimesheetFilter(filter);
    //console.log(resProject);
    setTimeSheetData(resProject.data);
  };

  const onSearhFailed = () => {
    message.error("ไม่สามารถค้นหาข้อมูลได้!");
  };

  const onProjectChange = async (e: any) => {
    formSearch.resetFields(["task"]);
    const resTask: any = await fetchTaskSelect(e);
    setTaskSelect(resTask.data);
  };

  const onSelectProjectChange = async (e: any) => {
    form.resetFields(["form_task"]);
    const resTask: any = await fetchTaskSelect(e);
    setFormTaskSelect(resTask.data);
  };

  const fetchAPIShiftEmp = async () => {
    const rsShiftEmp: any = await fetchShiftEmp();
    setShiftEmp(rsShiftEmp.data);
  };

  const panelItem: any = [
    {
      key: "1",
      label: "กะการทำงาน",
      style: panelStyle,
      children: (
        <>
          <Typography.Title level={5} style={{ margin: 0 }}>
            เวลา :
          </Typography.Title>
          <Typography.Paragraph>
            {dayjs(shiftEmp?.sTimeStart).format("HH:mm")} -{" "}
            {dayjs(shiftEmp?.sTimeEnd).format("HH:mm")}
          </Typography.Paragraph>
        </>
      ),
    },
    {
      key: "2",
      label: "วัน",
      children: (
        <Flex wrap gap="small">
          {shiftEmp?.sSunday && <Tag color="red">วันอาทิตย์</Tag>}
          {shiftEmp?.sMonday && <Tag color="gold">วันจันทร์</Tag>}
          {shiftEmp?.sTuesday && <Tag color="magenta">วันอังคาร</Tag>}
          {shiftEmp?.sWednesday && <Tag color="green">วันพุธ</Tag>}
          {shiftEmp?.sThursday && <Tag color="orange">วันพฤหัส</Tag>}
          {shiftEmp?.sFriday && <Tag color="blue">วันศุกร์</Tag>}
          {shiftEmp?.sSaturday && <Tag color="purple">วันเสาร์</Tag>}
        </Flex>
      ),
      style: panelStyle,
    },
  ];
  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (panelStyle) => panelItem;
  
  console.log('exportTblData',exportTblData);
  return (
    <>
      {isMobile && (
        <Typography.Text type="secondary" style={{ float: "right" }}>
          {" "}
          <ClockCircleOutlined /> บันทึกเวลา
        </Typography.Text>
      )}
      <Typography.Title level={3}>ค้นหาโดยระบุตัวกรอง</Typography.Title>
      <Form
        layout="vertical"
        form={formSearch}
        onFinish={onSearch}
        onFinishFailed={onSearhFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={12} className="pe-8px-filter pe-0px-filter">
            <FormSelect
              name="project"
              label="ระบุโครงการ"
              required={false}
              message={""}
              options={projectSelect}
              onChange={onProjectChange}
            />
          </Col>
          <Col xs={24} md={12} className="ps-8px-filter ps-0px-filter">
            <FormSelect
              name="task"
              label="ระบุงาน"
              required={false}
              message={""}
              options={taskSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} className="pe-8px-filter pe-0px-filter">
            <FormDatePicker name="start" label="วันที่เริ่ม" required={false} />
          </Col>
          <Col xs={24} md={12} className="ps-8px-filter ps-0px-filter">
            <FormDatePicker name="end" label="วันที่สิ้นสุด" required={false} />
          </Col>
        </Row>

        <Flex align="center" justify="center">
          <Button className="w-100-percent" type="primary" htmlType="submit">
            <SearchOutlined /> ค้นหา
          </Button>
        </Flex>
      </Form>
      <Divider className="mb-0px" />
      <Flex className="btn-add-time-sheet" key="AddEmp" align="flex-end" gap="small" vertical>
        {permTimeSheet?.add && (
          <Button id="AddEmp" type="primary" onClick={showDrawer}>
            <PlusOutlined />
            บันทึกเวลา
          </Button>
        )}
      </Flex>
      <br />
      <Drawer
        title="บันทึกเวลา"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ background: token.colorBgContainer }}
          items={getItems(panelStyle)}
        />
        <Divider />
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <FormSelect
            name="form_project"
            label="ระบุโครงการ"
            required={true}
            message="กรุณาระบุโครงการ"
            defaultValue={""}
            onChange={onSelectProjectChange}
            options={projectSelect}
            disabled={disable}
          />
          <FormSelect
            name="form_task"
            label="ระบุงาน"
            required={true}
            message="กรุณาระบุงาน"
            defaultValue={""}
            onChange={selectChange}
            options={formTaskSelect}
            disabled={disable}
          />
          <FormDatePicker
            name={"form_date"}
            label={"วันที่"}
            required={true}
            message="กรุณาระบุวันที่"
          />
          <FormTimePicker
            name="form_start"
            label="เวลาเริ่มงาน"
            required={true}
            message="กรุณาระบุเวลาเริ่มงาน"
          />
          <FormTimePicker
            name="form_end"
            label="เวลาออกงาน"
            required={true}
            message="กรุณาระบุเวลาออกงาน"
          />
          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>

      <CSVLink
        filename={"Timesheet.csv"}
        data={exportTblData}
        className="btn btn-primary"
        style={{ position: "absolute" }}
      >
        บันทึกเป็น CSV
      </CSVLink>
      <Table
        rowKey="id"
        columns={columns(EditProject, DeleteRecord, permTimeSheet)}
        dataSource={timeSheetData}
        searchable={!isMobile}
        style={isMobile ? { paddingTop: "50px" } : {}}
      />
    </>
  );
};

export default TimeSheet;
