import dayjs from "dayjs";
import { IObject } from "../interfaces/middleware/apiHandler";

const convertDateStringsToDates = (obj: IObject): IObject => {
  const newObj: IObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (
        typeof value === "string" &&
        value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)
      ) {
        // ถ้า value เป็น string ในรูปแบบ date ให้แปลงเป็น Date
        newObj[key] = dayjs(value);
      } else if (typeof value === "object" && value !== null) {
        // ถ้า value เป็น object ให้ทำ recursive call
        newObj[key] = convertDateStringsToDates(value);
      } else {
        // ถ้า value ไม่ใช่ date string หรือ object ให้เก็บค่าเดิม
        newObj[key] = value;
      }
    }
  }

  return newObj;
};

export const mappingObjDate = (dataObj: IObject) => {
  return convertDateStringsToDates(dataObj);
};
