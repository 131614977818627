import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchShift = () => {
  return requestHandler({
    method: "get",
    withCredentials: false,
    url: `${host}/shift`,
    isAuthen: true,
  });
};

export const requestUpdateShift = (data: IObject) => {
  return requestHandler({
    method: "put",
    withCredentials: false,
    url: `${host}/shift`,
    isAuthen: true,
    data,
  });
};

export const requestCreateShift = (data: IObject) => {
  return requestHandler({
    method: "post",
    withCredentials: false,
    url: `${host}/shift`,
    isAuthen: true,
    data,
  });
};

export const requestDeleteShift = (data: IObject) => {
  return requestHandler({
    method: "put",
    withCredentials: false,
    url: `${host}/shift/delete`,
    isAuthen: true,
    data,
  });
};

export const fetchShiftEmp = () => {
  return requestHandler({
    method: "get",
    withCredentials: false,
    url: `${host}/shift/emp`,
    isAuthen: true,
  });
};