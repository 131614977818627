import { Table } from "ant-table-extensions";
import { Button, Drawer, Flex, Form, message, Modal, Radio, RadioChangeEvent, Space, Typography } from "antd";
import { columns, IProject, IProjectForm, IProjectTblData, IRequestCreateProject, IRequestEditProject } from "../interfaces/IProject";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EStatusColumn } from "../interfaces/ICenter";
import { FormTextArea } from "../_start/layout/components/form-compoment/FormTextArea";
import { FormSwitch } from "../_start/layout/components/form-compoment/FormSwitch";
import { fetchCreateProject, fetchProject, fetchProjectDelete, fetchProjectEdit } from "../middleware/fetcher/project";
import { fetchBranch } from "../middleware/fetcher/branch";
import { IBranch } from "../interfaces/IBranch";
import { CSVLink } from "react-csv";
const { confirm } = Modal;

const Project: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [open, setOpen] = useState(false);
  const [projectData, setProjectData] = useState<Array<IRequestCreateProject>>([]);
  const [exportTblData, setExportTblData] = useState<Array<IProjectTblData>>([]);
  const [event, setEvent] = useState("");
  const [client, setClient] = useState<DropDown[]>([]);
  const [Public, setPublic] = useState(1);
  const [inputDisable, setInputDisable] = useState<boolean>();
  const permProject = useMemo(() => permissions?.project?.permission_action || {}, [permissions]);
  useEffect(() => {
    setMenuSelectedKey("3_1");
    getBranch();
    getProjectList();
  }, []);

  const getProjectList = async () => {
    const projectList: any = await fetchProject("");
    setProjectData(projectList.data);

    let tblData: IProjectTblData[] = [];
    let projectTblData : IProjectTblData;

    projectList.data.map((item: any) => {
        projectTblData = {
            PROJECT: item.name,
            DESCRIPTION: item.desc,
            คิดเงิน: (item.billable_is_default)?"Yes":"No",
            งบประมาณ: item.billable_rate,   
            CLIENT: item.client_code,
            STATUS: item.status
        }
        tblData.push(projectTblData);
    });
    setExportTblData(tblData);
  };

  const getBranch = async () => {
    const branch: any = await fetchBranch("all");
    const dropDownList: DropDown[] = [];
    if (branch.data != null) {
      for (let i = 0; i < branch.data.branch.length; i++) {
        const b: IBranch = branch.data.branch[i] as IBranch;
        if(b.brn_status === EStatusColumn.ACTIVE) {
          const drop: DropDown = {
            value: b.brn_code,
            label: b.brn_name,
          };
          dropDownList.push(drop);
        }
      }
    }
    setClient(dropDownList);
  };

  const [form] = Form.useForm<IProjectForm>();

  const showDrawer = () => {
    setEvent("add");
    setPublic(1);
    setOpen(true);
    setInputDisable(false);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const EditProject = (e: string) => {
    setEvent("edit");
    setInputDisable(true);
    const project = projectData.find((p) => p.code == e);
    if (project == null) {
      return;
    }
    const formProject: IProjectForm = {
      pro_code: project.code,
      pro_name: project.name,
      pro_desc: project.desc ?? "",
      client: project.client_code,
      status: project.status,
      pro_billing: project.billable_is_default,
      pro_budget: project.billable_rate ?? 0,
      is_public: project?.access_is_public,
    };

    setPublic(formProject.is_public ? 1 : 0);
    form.setFieldsValue(formProject);
    setOpen(true);
  };

  const DeleteRecord = async (code: string, name: string) => {
    await fetchProjectDelete(code);
    message.success(`ลบรายการ ${name} แล้ว`);
    await getProjectList();
  };

  const selectChange = (value: string) => {
    //console.log(`selected ${value}`);
  };

  const onFinish = async () => {
    const value: IProjectForm = { ...form.getFieldsValue() };
    let title = "";
    if (event == "add") {
      title = "ต้องการบันทึกข้อมูล";
    } else {
      title = "ต้องการบันทึกข้อมูล";
    }

    confirm({
      title: `${title} ${value.pro_name}ใช่หรือไม่?`,
      async onOk() {
        try {
          const code: string = value.pro_code;
          if (event == "add") {
            const dataCreate: IRequestCreateProject = {
              code: value.pro_code,
              name: value.pro_name,
              desc: value.pro_desc ?? "",
              client_code: value.client,
              status: value.status,
              access_is_public: Public == 1 ? true : false,
              billable_rate: Number(value.pro_budget),
              billable_is_default: value.pro_billing,
            };
            await fetchCreateProject(dataCreate);
          } else {
            const dataEdit: IRequestEditProject = {
              name: value.pro_name,
              desc: value.pro_desc ?? "",
              client_code: value.client,
              status: value.status,
              access_is_public: Public == 1 ? true : false,
              billable_rate: Number(value.pro_budget) ?? 0,
              billable_is_default: value.pro_billing,
            };

            await fetchProjectEdit(code, dataEdit);
          }

          const projectList = await fetchProject("");

          setProjectData(projectList.data);
          setOpen(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setPublic(e.target.value);
  };

  return (
    <>
      <Flex key="AddEmp" align="flex-end" gap="small" vertical>
        {permProject?.add && (
          <Button type="primary" onClick={showDrawer}>
            <PlusOutlined />
            เพิ่มโครงการ
          </Button>
        )}
      </Flex>
      <br />
      <Drawer
        title="บันทึกโครงการ"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form layout="vertical" form={form} autoComplete="off" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <FormInput name="pro_code" label="รหัสโครงการ" required={true} message="กรุณาระบุรหัสโครงการ" disabled={inputDisable} />
          <FormInput name="pro_name" label="ชื่อโครงการ" required={true} message="กรุณาระบุชื่อชื่อโครงการ" />
          <FormTextArea name="pro_desc" label="คำอธิบาย" required={false} message="กรุณาระบุคำอธิบาย" />
          <FormSelect name="client" label="สาขา" required={true} message="กรุณาเลือกสังกัด" defaultValue={""} onChange={selectChange} options={client} />
          <FormSelect name="status" label="สถานะ" required={true} message="กรุณาเลือกสถานะ" defaultValue={EStatusColumn.ACTIVE} onChange={selectChange} options={DropDown} />
          <FormSwitch name="pro_billing" label="ค่าใช้จ่าย" required={true} message="กรุณาระบุค่าใช้จ่าย" />
          <FormInput name="pro_budget" label="งบประมาณ'" required={true} message="กรุณาระบุงบประมาณ" inputType={"number"} />
          <Form.Item name="is_public" label={"Visibility"} valuePropName="checked">
            <Radio.Group onChange={onRadioChange} value={Public}>
              <Radio value={1}>Public</Radio>
              <Radio value={0}>Private</Radio>
            </Radio.Group>
          </Form.Item>
          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>

      <CSVLink filename={"Project.csv"} data={exportTblData} className="btn btn-primary" style={{position:"absolute"}}>
        บันทึกเป็น CSV
      </CSVLink>

      <Table columns={columns(EditProject, DeleteRecord, permProject)} dataSource={projectData} searchable />
    </>
  );
};

export default Project;
