import { Link } from "react-router-dom";
import { DropDown, TagRole, TagStatus } from "./ICenter";
import { Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Text } = Typography;

// Interface
export interface ITask {
    code: string;
    name: string;
    description: string;
    status: string;
    assigns: assign[];
    rate: number;
    use_bill: boolean;
}

export interface ITaskTblData {
    Task: string;
    Description: string;
    คิดเงิน: string;
    งบประมาณ: string; 
    ASSIGNEES: string;
    STATUS: string;
}

export interface assign {
    code: string;
    first_name: string;
    last_name: string;
}

export interface OptionType {
    value: string;
    label: string;
}

export interface ITaskForm extends Omit<ITask, "assigns"> {
    project: string;
    assigns: string[];
}

export interface ICreateTask extends Omit<ITask, "assigns"> {
    project: string;
    assigns: string[];
}

export interface IPatchTask extends ICreateTask {}

export interface IDeleteTask {
    code: string;
}

// Set columns
// Set columns for table
export const TaskColumns = (EditTask: any, DeleteRecord: any) => {
    return [
        {
            title: "งาน",
            dataIndex: "name",
            key: "name",
            render: (_: any, record: ITask) => (
                <>
                    <Link to={`/Task/${record.code}`} title="Task">
                        <Text underline>{record.name}</Text>
                        <br />
                        <Text type="secondary">{record.description}</Text>
                    </Link>
                </>
            ),
        },
        {
            title: "ค่าใช้จ่าย",
            dataIndex: "use_bill",
            key: "use_bill",
            render: (_:any, record:any) => (
                <>
                    {(record.use_bill)?"Yes":"No"}
                </>
            )
        },
        {
            title: "งบประมาณ",
            dataIndex: "rate",
            key: "rate",
        },
        {
            title: "จัดการพนักงาน",
            dataIndex: "assigns.first_name",
            key: "assigns.first_name",
            render: (_: any, record: ITask) =>
                record.assigns.map((assign: assign) => (
                    <Tag color="processing">
                        {assign.first_name} {assign.last_name}
                    </Tag>
                )),
        },
        {
            title: "สถานะ",
            dataIndex: "status",
            key: "status",
            render: (_: any, record: ITask) =>
                TagStatus(record.status, record.code),
        },
        {
            title: "ดำเนินการ",
            key: "task_code",
            render: (_: any, record: ITask) => (
                <Space size="middle">
                    <EditOutlined
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => EditTask(record)}
                    />

                    <Popconfirm
                        onConfirm={() => DeleteRecord(record.code, record.name)}
                        title="ยืนยันการลบข้อมูล"
                        description="คุณต้องลบข้อมูลหรือไม่?"
                    >
                        <DeleteOutlined style={{ color: "red" }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];
};

// Set ASSIGNEES columns for table
export const AssignColumns = (DeleteRecord: any) => {
    return [
        {
            title: "รหัสนักงาน",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "ชื่อพนักงาน",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "ติดต่อ",
            dataIndex: "contact",
            key: "contact",
        },
        {
            title: "สิทธิ์",
            dataIndex: "role",
            key: "role"
        },
        // {
        //     title: "ACTION",
        //     key: "task_code",
        //     render: (_: any, record: ITask) => (
        //         <Space size="middle">
        //             <Popconfirm
        //                 onConfirm={() => DeleteRecord(record.code, record.name)}
        //                 title="ยืนยันการลบข้อมูล"
        //                 description="คุณต้องลบข้อมูลหรือไม่?"
        //             >
        //                 <DeleteOutlined style={{ color: "red" }} />
        //             </Popconfirm>
        //         </Space>
        //     ),
        // },
    ];
}

// Data mockup
// export const mocTask: ITask[] = [
//     {
//         code: "TASK001",
//         name: "TEST 001",
//         description: "TASK TEST Description",
//         status: "active",
//         assigns: [
//             {
//                 emp_code: "EMP001",
//                 emp_first_name: "Jhone",
//                 emp_last_name: "Wick",
//             },
//             {
//                 emp_code: "EMP002",
//                 emp_first_name: "Raiden",
//                 emp_last_name: "Meai",
//             },
//         ],
//     },
// ];

export const userAssignees: OptionType[] = [
    {
        value: "EMP001",
        label: "EMP001",
    },
    {
        value: "EMP002",
        label: "EMP002",
    },
];
