import React from "react";
import { Form, Checkbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import { IObject } from "../../../../interfaces/middleware/apiHandler";

type FormCheckboxProps = {
  name: string;
  label: string;
  required?: boolean;
  message?: string;
  defaultValue?: boolean;
  onChange?: CheckboxProps["onChange"];
  style?: IObject;
};

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  required,
  message,
  defaultValue,
  onChange,
  style = { marginBottom: "0" },
}) => {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={[{ required, message }]}
      initialValue={defaultValue || false}
      style={style}
    >
      <Checkbox onChange={onChange}>{label}</Checkbox>
    </Form.Item>
  );
};

export { FormCheckbox };
