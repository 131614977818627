import { Link } from "react-router-dom";
import { EStatusColumn, TagStatus } from "./ICenter";
import { Popconfirm, Space, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Text } = Typography;

export interface IRoles {
    role_code: string;
    role_name: string;
    role_status: string;
}

export interface IRolePermission {
    role_code: string,
    role_name: string | null,
    role_perm_act: string | null,
    role_status: EStatusColumn,
    role_created_by: string | null,
    role_createdAt?: Date,
    role_updated_by: string | null,
    role_updatedAt?: Date
}

export interface IRequestUpdateRole {
    name:string | null,
    status: string
}

export interface IRequestCreateRole extends IRequestUpdateRole{
    code: string,
}

export interface IRequestRolePermAct {
    code: string,
    name: string,
    desc: string | null,
    act: IRequestAct
}

export interface IRequestAct {
    view: boolean,
    add: boolean,
    edit: boolean,
    delete: boolean
}

export interface IRolePermAct {
    permission_code: string,
    permission_name: string | null,
    permission_desc: string | null,
    permission_action: any
}

// Data mockup
// export let mockData: IRoles[] = [
//     {
//         rCode: "RO001",
//         rName: "Travel",
//         rDesc: "Choose from wide range of travel accessories from popular brands",
//         rStatus: EStatusColumn.ACTIVE,
//     },
//     {
//         rCode: "RO002",
//         rName: "Beauty & Personal Care",
//         rDesc: "Choose from wide range of travel accessories from popular brands",
//         rStatus: EStatusColumn.INACTIVE,
//     },
// ];

export const columns = (EditProject: any, DeleteRecord: any, permRole: any) => {
    return [
        {
            title: "รหัสสิทธิ์",
            dataIndex: "role_code",
            key: "role_code",
            render: (_: any, record: any) => (
                <>
                    <Link to={`/Permission/${record.role_code}`} title="Permission">
                        <Text underline>{record.role_code}</Text>
                        <br />
                        <Text type="secondary">{record.rDesc}</Text>
                    </Link>
                </>
            ),
        },
        {
            title: "ชื่อสิทธิ์",
            dataIndex: "role_name",
            key: "role_name",
        },
        {
            title: "สถานะ",
            dataIndex: "role_status",
            key: "role_status",
            render: (_: any, record: any) =>
                TagStatus(record.role_status, record.role_code),
        },
        {
            title: "ดำเนินการ",
            key: "role_code",
            render: (_: any, record: any) => (
                <Space size="middle">
                    {permRole?.edit && <EditOutlined
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => EditProject(record.role_code)}
                    />}

                    <Popconfirm
                        onConfirm={() => DeleteRecord(record.role_code)}
                        title="ยืนยันการลบข้อมูล"
                        description="คุณต้องลบข้อมูลหรือไม่?"
                    >
                        {permRole?.delete && <DeleteOutlined style={{ color: "red" }} />}
                    </Popconfirm>
                </Space>
            ),
        },
    ];
};