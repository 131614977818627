import { Tag } from "antd";

export interface DropDown {
  value: string;
  label: string;
}

export enum EStatusColumn {
  ACTIVE = "active",
  INACTIVE = "inactive",
  // DELETE = "delete",
}

export enum EStatusLabel {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  // DELETE = "DELETE",
}

export enum EEvent {
  EDIT = "edit",
  ADD = "add"
}

export const DropDown: DropDown[] = [
  { value: EStatusColumn.ACTIVE, label: EStatusLabel.ACTIVE },
  { value: EStatusColumn.INACTIVE, label: EStatusLabel.INACTIVE },
  // { value: EStatusColumn.DELETE, label: EStatusLabel.DELETE },
];

export const TagStatus = (status: string, code: string) => {
  switch (status) {
    case "active":
      return (
        <Tag color="green" key={`active-${code}`}>
          Active
        </Tag>
      );

    case "inactive":
      return (
        <Tag color="yellow" key={`active-${code}`}>
          Inactive
        </Tag>
      );

    case "delete":
      return (
        <Tag color="red" key={`active-${code}`}>
          Delete
        </Tag>
      );
    default:
      return (
        <Tag color="green" key={`active-${code}`}>
          Active
        </Tag>
      );
  }
};

export const TagImStatus = (status: string, code: string) => {
  switch (status) {
    case "SUCCESS":
      return (
        <Tag color="green" key={`active-${code}`}>
          SUCCESS
        </Tag>
      );

    case "FAILED":
      return (
        <Tag color="red" key={`active-${code}`}>
          FAILED
        </Tag>
      );

    default:
      return (
        <Tag color="yellow" key={`active-${code}`}>
          Error
        </Tag>
      );
  }
};

export const TagRole = (role: string, code: string) => {
  switch (role) {
    case "admin":
      return (
        <Tag color="green" key={`active-${code}`}>
          ADMIN
        </Tag>
      );

    case "user":
      return (
        <Tag color="yellow" key={`active-${code}`}>
          USER
        </Tag>
      );

    case "manage":
      return (
        <Tag color="red" key={`active-${code}`}>
          MANAGE
        </Tag>
      );
    default:
      return (
        <Tag color="yellow" key={`active-${code}`}>
          USER
        </Tag>
      );
  }
};
