import React from "react";
import { useParams } from "react-router-dom";
import { Button, Drawer, Form, message, Space, Modal } from "antd";
import { FormInput, FormSelect, FormSelectMultiTag, FormSwitch, FormTextArea } from "../../../form-compoment";
import { EStatusColumn, DropDown } from "../../../../../../interfaces/ICenter";
import { fetchTasks, requestCreateTasks, requestUpdateTasks } from "../../../../../../middleware/fetcher/task";
import { ICreateTask, IPatchTask, ITaskForm } from "../../../../../../interfaces/ITask";
import { fetchEmployee } from "../../../../../../middleware/fetcher/employee";
const { confirm } = Modal;

type DrawerTaskInterface = {
  setOpen?: any;
  open: boolean;
  selectChange: any;
  assignEmpList: any;
  DropDown: any;
  form: any;
  isEditTask: boolean;
  setIsEditTask: any;
  fetchAPI: any;
  options?: any;
  access_is_public?: any;
};

const DrawerTask: React.FC<DrawerTaskInterface> = ({ setOpen, open, selectChange, assignEmpList, DropDown, form, isEditTask, setIsEditTask, fetchAPI, options, access_is_public = true }) => {
  const { pro_code } = useParams();
  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    values.rate = Number(values.rate);
    confirm({
      title: `ต้องการบันทึกข้อมูลใช่หรือไม่?`,
      async onOk() {
        try {
          if (isEditTask) {
            await requestUpdateTasks({
              ...values,
              project: pro_code,
            });
            setOpen(false);
            setIsEditTask(false);
            message.success("บันทึกข้อมูลสำเร็จ!");
            await fetchAPI();
          } else {
            const rs = await requestCreateTasks({ ...values, project: pro_code });
            if (rs.status === 400) {
              message.error(rs.message);
            } else {
              setOpen(false);
              setIsEditTask(false);
              message.success("บันทึกข้อมูลสำเร็จ!");
              await fetchAPI();
            }
          }
        } catch (error) {
          message.error("บันทึกข้อมูลล้มเหลว!");
        }
      },
      onCancel() {},
    });
  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  /** */
  let empSelect: DropDown;
  let arrEmp: DropDown[] = [];

  assignEmpList.map((item: any) => {
    empSelect = {
      value: item.code,
      label: item.name,
    };
    arrEmp.push(empSelect);
  });

  const setOption = [...options, ...arrEmp];
  let newArr: any[] = [];
  let key: any[] = [];
  for (let i = 0; i < setOption.length; i++) {
    if (!key.includes(setOption[i].value)) {
      newArr.push(setOption[i]);
      key.push(setOption[i].value);
    }
  }

  return (
    <Drawer
      title="Task"
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form layout="vertical" form={form} autoComplete="off" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <FormInput name="code" label="รหัสงาน" required={true} message="กรุณาระบุรหัสงาน" disabled={isEditTask} />
        <FormInput name="name" label="ชื่องาน" required={true} message="กรุณาระบุชื่องาน" />
        <FormTextArea name="description" label="คำอธิบาย" required={false} message="กรุณาระบุคำอธิบาย" />
        {!access_is_public && <FormSelectMultiTag name="assigns" label="จัดการพนักงาน" required={true} message="กรุณาเลือกพนักงาน" onChange={selectChange} options={newArr} defaultValue={assignEmpList.map((x: any) => x.code)} keySelect="value" labelSelect="label" />}
        <FormSelect name="status" label="สถานะ" required={true} message="กรุณาเลือกสถานะ" defaultValue={EStatusColumn.ACTIVE} onChange={selectChange} options={DropDown} />
        <FormSwitch name="use_bill" label="ค่าใช้จ่าย" required={true} message="กรุณาระบุค่าใช้จ่าย" />
        <FormInput name="rate" label="งบประมาณ'" required={true} message="กรุณาระบุงบประมาณ" inputType="number" />
        <Space>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button type="primary" htmlType="submit">
            บันทึก
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};

export default DrawerTask;
