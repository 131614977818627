import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  message,
  Row,
  Select,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import {
  ITask,
  userAssignees,
  ICreateTask,
  AssignColumns,
} from "../interfaces/ITask";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import {
  FormSelectMultiTag,
  SelectMultiTag,
} from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown } from "../interfaces/ICenter";
import Chart from "react-apexcharts";
import { fetchTasks } from "../middleware/fetcher/task";
import isEmpty from "is-empty";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../constants/page";
import DrawerTask from "../_start/layout/components/Page/Project/Task/DrawerTask";
import TaskTab from "../_start/layout/components/Page/Project/Task";
import {
  fetchProjectAssignEmp,
  putProjectAssign,
} from "../middleware/fetcher/projectAccess";
import { IEmployee } from "../interfaces/IEmployee";
import { fetchProject } from "../middleware/fetcher/project";
import {
  IProject,
  IProjectAccessSelect,
  IPutProjectAssign,
  IRequestCreateProject,
} from "../interfaces/IProject";
import { fetchEmployee } from "../middleware/fetcher/employee";
import { IObject } from "../interfaces/middleware/apiHandler";
import { Table } from "ant-table-extensions";
const { Title } = Typography;
const Task: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const { pro_code } = useParams();
  const [form] = Form.useForm<ICreateTask>();
  const [open, setOpen] = useState(false);
  const [taskData, setTaskData] = useState<Array<ITask>>([]);
  const [assignEmpList, setAssignEmpList] = useState<any>([]);
  const [isEditTask, setIsEditTask] = useState(false);
  const [employees, setEmployee] = useState<DropDown[]>([]);
  const [option, setOption] = useState<DropDown[]>([]);
  const [project, setProject] = useState<IRequestCreateProject>();
  const [selectEmp, setSelectEmp] = useState<string[]>([]);
  const [isSent, setSent] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setMenuSelectedKey("3_1");
    if (pro_code == null) {
      navigate(pageRoute.home);
    } else {
      fetchAPI();
    }
  }, []);

  const fetchAPI = async () => {
    const apiResult = await fetchProject(pro_code);
    const project = apiResult.data[0] as IRequestCreateProject;
    setProject(project);

    const task = await fetchTasks(pro_code ?? "");
    if (!isEmpty(task.data)) {
      setTaskData(task?.data as ITask[]);
    }

    let assigns = await fetchAssignEmp();
    if (!project?.access_is_public) {
      const allEmp = await fetchEmployee("all");
      const empData = allEmp.data.employee as IEmployee[];
      setEmployee(
        empData.map((x) => {
          const selectData: DropDown = {
            value: x.emp_code,
            label: `${x.emp_first_name} ${x.emp_last_name}`,
          };
          return selectData;
        })
      );
      const arr = assigns.map((x: any) => x.code);

      setSelectEmp([...arr]);
    }
  };
  console.log("selectEmp", selectEmp);
  const fetchAssignEmp = async () => {
    const assignEmpTemp = await fetchProjectAssignEmp(pro_code ?? "");

    if (!isEmpty(assignEmpTemp?.data)) {
      setAssignEmpList(assignEmpTemp?.data?.access);
      return assignEmpTemp?.data?.access;
    }

    return [];
  };

  const showDrawer = async () => {
    setOpen(true);
    setIsEditTask(false);
    let assigns = await fetchAssignEmp();
    if (!project?.access_is_public) {
      const allEmp = await fetchEmployee("all");
      const empData = allEmp.data.employee as IEmployee[];
      setEmployee(
        empData.map((x) => {
          const selectData: DropDown = {
            value: x.emp_code,
            label: `${x.emp_first_name} ${x.emp_last_name}`,
          };
          return selectData;
        })
      );
      const arr = assigns.map((x: any) => x.code);
      setSelectEmp([...arr]);
    }
  };

  const onChange = (key: string) => {};

  const selectChange = (value: string) => {};

  const selectUpdateProjectAccess = async (empCode: string[]) => {
    // TODO Add Spinner
    // wait 2 to fire api
    console.log("selectUpdateProjectAccess");
    message.info("Wait to update.");
    if (isSent) {
      message.info("Wait to update.");
    } else {
      setSent(true);
      const insertEmp = empCode.filter((x) => !selectEmp.includes(x));
      const delEmp = selectEmp.filter((x) => !empCode.includes(x));
      if (!isEmpty(insertEmp)) {
        insertEmp.map(async (x) => {
          const acc: IPutProjectAssign = {
            project_code: pro_code ?? "",
            op: "assign",
            emp_code: x,
          };

          await putProjectAssign(acc);
        });
      }

      if (!isEmpty(delEmp)) {
        delEmp.map(async (x) => {
          const acc: IPutProjectAssign = {
            project_code: pro_code ?? "",
            op: "unassign",
            emp_code: x,
          };

          await putProjectAssign(acc);
        });
      }

      setSelectEmp(empCode);
      setSent(false);
    }

    await fetchAssignEmp();
    return;
  };

  const DeleteRecord = async (e: string) => {
    console.log(e);
  };

  let items: TabsProps["items"] = [];
  if (project?.access_is_public) {
    items = [
      {
        key: "1",
        label: "รายการงาน",
        children: (
          <>
            <TaskTab
              form={form}
              taskData={taskData}
              setOpen={setOpen}
              setIsEditTask={setIsEditTask}
              fetchApi={fetchAPI}
              setOption={setOption}
            />
          </>
        ),
      },
      {
        key: "2",
        label: "สาถานะ",
        children: (
          <>
            <Flex vertical={false} justify="space-around" align="center">
              <Col span={14}>
                <Space
                  size={70}
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <Card>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        TRACKED
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        BILLABLE :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        NON-BILLABLE :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        AMOUNT :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 BTH
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col span={10}>
                <Chart
                  options={{
                    chart: {
                      id: "apexchart-example",
                    },
                    xaxis: {
                      categories: [
                        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                      ],
                    },
                  }}
                  series={[
                    {
                      name: "series-1",
                      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                    },
                  ]}
                  type="bar"
                />
              </Col>
            </Flex>
          </>
        ),
      },
    ];
  } else {
    items = [
      {
        key: "1",
        label: "จัดการพนักงาน",
        disabled: project?.access_is_public,
        children: (
          <>
            <Space
              direction="vertical"
              size="large"
              style={{
                display: "flex",
              }}
            >
              <Select
                defaultValue={selectEmp}
                value={selectEmp}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="ASSIGNEES"
                onChange={selectUpdateProjectAccess}
                options={employees.map((option: IObject) => ({
                  value: option["value"],
                  label: option["label"],
                }))}
              />
            </Space>
            <br />
            <Table
              columns={AssignColumns(DeleteRecord)}
              dataSource={assignEmpList}
              searchable
            />
          </>
        ),
      },
      {
        key: "2",
        label: "รายการงาน",
        children: (
          <>
            <TaskTab
              form={form}
              taskData={taskData}
              setOpen={setOpen}
              setIsEditTask={setIsEditTask}
              fetchApi={fetchAPI}
              setOption={setOption}
            />
          </>
        ),
      },
      {
        key: "3",
        label: "สถานะ",
        children: (
          <>
            <Flex vertical={false} justify="space-around" align="center">
              <Col span={14}>
                <Space
                  size={70}
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <Card>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        TRACKED
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        BILLABLE :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        NON-BILLABLE :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 h
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Row>
                      <Col
                        span={12}
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                        }}
                      >
                        AMOUNT :
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                        }}
                      >
                        0.00 BTH
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col span={10}>
                <Chart
                  options={{
                    chart: {
                      id: "apexchart-example",
                    },
                    xaxis: {
                      categories: [
                        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                      ],
                    },
                  }}
                  series={[
                    {
                      name: "series-1",
                      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                    },
                  ]}
                  type="bar"
                />
              </Col>
            </Flex>
          </>
        ),
      },
    ];
  }

  return (
    <>
      <Flex key="AddEmp" align="flex-end" gap="small" vertical>
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined />
          เพิ่มงาน
        </Button>
      </Flex>
      <Tabs
        type="card"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
      <DrawerTask
        setOpen={setOpen}
        open={open}
        assignEmpList={assignEmpList}
        DropDown={DropDown}
        selectChange={selectChange}
        form={form}
        setIsEditTask={setIsEditTask}
        isEditTask={isEditTask}
        fetchAPI={fetchAPI}
        options={option}
        access_is_public={project?.access_is_public}
      />
    </>
  );
};

export default Task;
