import { Routes, Route, Navigate } from "react-router-dom";
import Employee from "../pages/Employee";
import Home from "../pages/Home";
import Page404 from "../pages/Page404";
import Logout from "../pages/Logout";
import Branch from "../pages/Branch";
import Department from "../pages/Department";
import Shift from "../pages/Shift";
import CalendarTime from "../pages/Calendar";
import Project from "../pages/Project";
import Task from "../pages/Task";
import Roles from "../pages/Roles";
import Permission from "../pages/Permission";
import ImportEmp from "../pages/ImportEmp";
import TimeSheet from "../pages/TimeSheet";
import TimeTracker from "../pages/TimeTracker";
import { AppLayout } from "../_start/layout/AppLayout";
import DailyReport from "../pages/DailyReport";
import WeeklyReport from "../pages/WeeklyReport";
import AttendanceReport from "../pages/AttendanceReport";
import AssignmentsReport from "../pages/AssignmentsReport";
import DetailedReport from "../pages/DetailedReport";
import SummaryReport from "../pages/SummaryReport";

export const PrivateRoutes = [
  <Route
    key={"route-404"}
    path="/404"
    element={
      <AppLayout key={"404"}>
        <Page404 />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-Logout"}
    path="/Logout"
    element={
      <AppLayout key={"layout-Logout"}>
        <Logout />
      </AppLayout>
    }
  />,
  <Route
    key={"route-Home"}
    path="/Home"
    element={
      <AppLayout key={"Home"}>
        <Home />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-TimeTracker"}
    path="/TimeTracker"
    element={
      <AppLayout key={"TimeTracker"}>
        <TimeTracker />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-TimeSheet"}
    path="/TimeSheet"
    element={
      <AppLayout key={"TimeSheet"}>
        <TimeSheet />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-CalendarTime"}
    path="/CalendarTime"
    element={
      <AppLayout key={"CalendarTime"}>
        <CalendarTime />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-SummaryReport"}
    path="/SummaryReport"
    element={
      <AppLayout key={"SummaryReport"}>
        <SummaryReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-DailyReport"}
    path="/DailyReport"
    element={
      <AppLayout key={"DailyReport"}>
        <DailyReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-WeeklyReport"}
    path="/WeeklyReport"
    element={
      <AppLayout key={"WeeklyReport"}>
        <WeeklyReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-AttendanceReport"}
    path="/AttendanceReport"
    element={
      <AppLayout key={"AttendanceReport"}>
        <AttendanceReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-AssignmentsReport"}
    path="/AssignmentsReport"
    element={
      <AppLayout key={"AssignmentsReport"}>
        <AssignmentsReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-DetailedReport"}
    path="/DetailedReport"
    element={
      <AppLayout key={"DetailedReport"}>
        <DetailedReport />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-Project"}
    path="/Project"
    element={
      <AppLayout key={"Project"}>
        <Project />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-pro_code"}
    path="/Task/:pro_code"
    element={
      <AppLayout key={"pro_code"}>
        <Task />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-employee"}
    path="/employee"
    element={
      <AppLayout key={"employee"}>
        <Employee />
      </AppLayout>
    }
  />,
  <Route
    key={"route-Branch"}
    path="/Branch"
    element={
      <AppLayout key={"Branch"}>
        <Branch />
      </AppLayout>
    }
  />,
  <Route
    key={"route-Department"}
    path="/Department/:id/:name"
    element={
      <AppLayout key={"Department"}>
        <Department />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-Shift"}
    path="/Shift"
    element={
      <AppLayout key={"Shift"}>
        <Shift />
      </AppLayout>
    }
  />,
  <Route
    key={"route-ImportEmp"}
    path="/ImportEmp"
    element={
      <AppLayout key={"ImportEmp"}>
        <ImportEmp />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-Roles"}
    path="/Roles"
    element={
      <AppLayout key={"Roles"}>
        <Roles />{" "}
      </AppLayout>
    }
  />,
  <Route
    key={"route-Permission"}
    path="/Permission/:role_code"
    element={
      <AppLayout key={"Permission"}>
        <Permission />
      </AppLayout>
    }
  />,
];
