import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const requestImportEmp = (data: IObject) => {
  return requestHandler({
    method: "post",
    withCredentials: false,
    url: `${host}/ipEmp`,
    isAuthen: true,
    data,
  });
};

export const fetchImportEmp = () => {
  return requestHandler({
    method: "get",
    withCredentials: false,
    url: `${host}/ipEmp`,
    isAuthen: true,
  });
};
