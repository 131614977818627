import { Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import { TagStatus } from "./ICenter";

export interface IDepartment {
  brn_code: string;
  dept_code: string;
  dept_name: string;
  dept_desc: string;
  dept_status: string;
}

export interface IDepartmentTblData {
  รหัสหน่วยงาน: string;
  หน่วยงาน: string;
  รายละเอียด: string;
  สถานะ: string;
}

export interface ITreeDepart {
  value?: string;
  title?: string;
  children: IChildren[];
}

export interface IChildren {
  value: string;
  title: string;
}

export interface IRequestCreateDepartment {
  code: string;
  name: string;
  desc: string;
  status: string | undefined;
  brn_code: string | undefined;
}

export interface IRequestUpdateDepartment {
  name: string;
  desc: string;
  status: string;
}

// Data mockup
export let mockData: IDepartment[] = [
  {
    brn_code: "BRN01",
    dept_code: "DEPT01",
    dept_name: "การตลาด",
    dept_desc: "รายละเอียดเพิ่มเติม",
    dept_status: "inactive",
  },
];

// Set columns for table
export const columns = (EditDept: any, DeleteRecord: any, permDepartment: any) => {
  return [
    {
      title: "รหัสหน่วยงาน",
      dataIndex: "dept_code",
      key: "dept_code",
    },
    {
      title: "หน่วยงาน",
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: "รายละเอียด",
      dataIndex: "dept_desc",
      key: "dept_desc",
    },
    {
      title: "สถานะ",
      key: "dept_status",
      dataIndex: "dept_status",
      render: (_: any, record: any) =>
        TagStatus(record.dept_status, record.dept_code),
    },
    {
      title: "ดำเนินการ",
      key: "action_dept_code",
      render: (_: any, record: any) => (
        <Space size="middle">
          {permDepartment?.edit && <EditOutlined
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => EditDept(record.dept_code)}
          />}

          <Popconfirm
            onConfirm={() => DeleteRecord(record.dept_code)}
            title="ยืนยันการลบข้อมูล"
            description="คุณต้องลบข้อมูลหรือไม่?"
          >
            {permDepartment?.delete && <DeleteOutlined style={{ color: "red" }} />}
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
