import { Form, Input } from "antd";
const { TextArea } = Input;
type InputProps = {
  name: string;
  label: string;
  required?: boolean;
  message?: string;
  type?: any;
  defaultValue?: any;
};

const FormTextArea: React.FC<InputProps> = ({
  name,
  label,
  required,
  message,
  type,
  defaultValue,
}: InputProps) => {
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            type: type,
            required: required,
            message: message,
          },
        ]}
        initialValue={defaultValue || null}
      >
        <TextArea
          name={name}
          placeholder={message}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
    </>
  );
};

export { FormTextArea };
