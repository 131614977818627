import React, { useEffect, useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/th";
import "../components/custom-style.css";
import { allEvents, ICalendar, IEvents } from "../../../interfaces/ICalendar";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Calendar } from '@fullcalendar/core';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import * as bootstrap from "bootstrap";
import { useAppDataContext } from "../core/AppProvider";
const CalendarEvent: React.FC<ICalendar> = ({ setDrawer, dateEvent, taskEvent, events, setIsTaskDelete, setDisable }: ICalendar) => {
  const [chkDeviceWidth , setChkDeviceWidth] = useState({});
  const danger = "#ff3e1d";
  const primary = "#696cff";
  const warning = "#ffab00";
  const info = "#03c3ec";
  const success = "#71dd37";
  const { permissions, deviceWidth, setDeviceWidth  } = useAppDataContext();
  const permCalendar = useMemo(() => permissions?.calendar?.permission_action || {}, [permissions]);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    
    if(width > 980){
      setChkDeviceWidth({
        left: "prev,next today myCustomButton",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      });
    }else{
      setChkDeviceWidth({});
    }
  }
  useEffect(()=>{
    getWindowDimensions();
  }, []);
  return (
    <FullCalendar
      locale={esLocale}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin]}
      themeSystem="bootstrap5"
      height={"650px"}
      headerToolbar={chkDeviceWidth}
      initialView="dayGridMonth"
      initialDate={new Date().toISOString().slice(0, 10)}
      weekends={true}
      events={events}
      eventDidMount={(info) => {
        return new bootstrap.Popover(info.el, {
          title: info.event.title,
          placement: "auto",
          trigger: "hover",
          customClass: "popoverStyle",
          content:
            `<p>${info.event.extendedProps.description}</p>`,
          html: true,
        });
      }}
      customButtons={{
        myCustomButton: {
          text: "บันทึกเวลา",
          click: () => {
            if (permCalendar?.add !== false) {
              return setDrawer(true);
            }
          },
        },
      }}
      dateClick={(info) => {
        dateEvent(info.dateStr);
        setIsTaskDelete(false);
        setDisable(false);
      }}
      eventClick={(info) => {
        setIsTaskDelete(info.event.extendedProps.is_task_delete);
        taskEvent(info.event.id);
      }}
    />
  );
};

export { CalendarEvent };
