import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchDepartment = (params?: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/department/list/${params}`,
        isAuthen: true
    });
}

export const fetchDepartmentEdit = (param: string, body: any) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/department/edit/${param}`,
        data: body,
        isAuthen: true
    });
}

export const fetchDepartmentCreate = (body: any) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/department/create`,
        data: body,
        isAuthen: true
    });
}