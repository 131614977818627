import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchTasks = (projectCode: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/task/list/${projectCode}`,
    });
};

export const requestCreateTasks = (data: IObject) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/task`,
        data,
    });
};

export const requestUpdateTasks = (data: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/task`,
        data,
    });
};

export const requestDeleteTask = (data: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/task/delete`,
        data,
    });
};
