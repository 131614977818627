import { Form, TimePicker } from "antd";
import dayjs from "dayjs";

type InputProps = {
  name: string;
  label?: string;
  required?: boolean;
  message?: string;
  type?: any;
  disabled?:boolean;
};

const FormTimePicker: React.FC<InputProps> = ({
  name,
  label,
  required,
  message,
  disabled=false
}: InputProps) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: message,
        },
        {
          validator: (rule, value) => {
            if (value && value.isSame(dayjs("00:00", "HH:mm"))) {
              return Promise.resolve();
            }
            return Promise.resolve(); // Allow any time including 00:00
          },
        },
      ]}
    >
      <TimePicker format={"HH:mm"} style={{ width: "100%" }} disabled={disabled} />
    </Form.Item>
  );
};

export { FormTimePicker };
