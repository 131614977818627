import React, { useState,useEffect } from "react";
import { AppHeader } from "./components/AppHeader";
import { AppFooter } from "./components/AppFooter";
import { AppSider } from "./components/AppSider";
import { AppContent } from "./components/AppContent";
import { AppBreadcrumb } from "./components/AppBreadcrumb";
import { useAppDataContext } from "./core/AppProvider";

import { Layout, theme } from "antd";

type Props = {
  children: any;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { isMobile } = useAppDataContext();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppSider {...{ collapsed, setCollapsed }} />

      <Layout style={{ marginLeft: isMobile ? 0 : collapsed ? 80 : 200 }}>
        <AppHeader {...{ collapsed, colorBgContainer, setCollapsed }} />

        <Layout className="layout-content-wrapper">
          <AppContent {...{ colorBgContainer, borderRadiusLG, children }} />
        </Layout>

        <AppFooter />
      </Layout>
    </Layout>
  );
};

export { AppLayout };
