import { Link } from "react-router-dom";
import { DropDown, TagStatus } from "./ICenter";
import { Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
const { Text } = Typography;

export interface TimeSheetInit {
  id: string;
  project: {
    project_name: string;
    description: string;
  };
  task: {
    task_name: string;
    description: string;
  };
  date: string;
  time: string;
  hour: string;
}

export interface TimeSheetTblData{
 โครงการ: string;
 รายละเอียดโครงการ: string;
 งาน: string;
 รายละเอียดงาน: string;
 วันที่: string;
 เวลา: string;
 ชั่วโมง: string;
}

export interface ITimeSheet {
  project_code: string;
  project: string;
  project_desc: string;
  task: string;
  task_desc: string;
  date: string;
  time: string;
  hour: string;
}

export interface OptionType {
  value: string;
  label: string;
}

export interface TimeSheetFilter {
  project: string;
  task: string;
  start: string;
  end: string;
}

export interface TimeSheetCreate extends TimeSheetFilter {
  date: Date;
}

export interface TimeSheetForm {
  form_project: string;
  form_task: string;
  form_start: any;
  form_end: any;
  form_date: any;
}

export interface TimeSheetUpdate {
  id: string;
  date: Date;
  start: string;
  end: string;
}
//Mockup Data
export const mockSheetData: ITimeSheet[] = [
  {
    project_code: "P001",
    project: "Travel",
    project_desc: "Choose from wide range of travel accessories from popular brands",
    task: "Task 1",
    task_desc: "Example task 1",
    date: "1 Jun 2024",
    time: "00:08 AM - 12:00 PM",
    hour: "4 Hr.",
  },
  {
    project_code: "P002",
    project: "Beauty & Personal Care",
    project_desc: "Choose from wide range of beauty & personal care from popular brands",
    task: "Task 2",
    task_desc: "Example task 2",
    date: "2 Jun 2024",
    time: "00:13 AM - 17:00 PM",
    hour: "4 Hr.",
  },
];

export const mockProject: OptionType[] = [
  {
    value: "P001",
    label: "Travel",
  },
  {
    value: "P002",
    label: "Beauty & Personal Care",
  },
];

export const mockTask: OptionType[] = [
  {
    value: "T001",
    label: "Task 1",
  },
  {
    value: "T002",
    label: "Task 2",
  },
];

// Set columns for table
export const columns = (EditProject: any, DeleteRecord: any, permTimeSheet: any) => {
  return [
    {
      title: "โครงการ",
      dataIndex: "project.project_name",
      key: "project.project_name",
      render: (_: any, record: any) => (
        <>
          <Text underline>
            {record.project.project_name}
            {record.project.is_public && (
              <Tag bordered={false} color="green" style={{ marginLeft: 5 }}>
                Public
              </Tag>
            )}
            {!record.project.is_public && (
              <Tag bordered={false} color="blue" style={{ marginLeft: 5 }}>
                Private
              </Tag>
            )}
          </Text>
          <br />
          <Text type="secondary">{record.project.description}</Text>
        </>
      ),
    },
    {
      title: "งาน",
      dataIndex: "task.task_name",
      key: "task.task_name",
      render: (_: any, record: any) => (
        <>
          <Text underline style={{ color: record.is_task_delete ? "#cf1322" : "" }}>
            {record.task.task_name}{" "}
            {record.is_task_delete && (
              <Tag bordered={false} color="error" style={{ marginLeft: 5 }}>
                Delete
              </Tag>
            )}
          </Text>
          <br />
          <Text type="secondary">{record.task.description}</Text>
        </>
      ),
    },
    {
      title: "วันที่",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "เวลา",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "ชั่วโมง",
      dataIndex: "hour",
      key: "hour",
    },
    {
      title: "ดำเนินการ",
      key: "id",
      render: (_: any, record: any) => (
        <>
          {!record.is_task_delete && (
            <Space size="middle">
              {permTimeSheet?.edit && <EditOutlined style={{ cursor: "pointer", color: "blue" }} onClick={() => EditProject(record.id)} />}

              <Popconfirm onConfirm={() => DeleteRecord(record.id)} title="ยืนยันการลบข้อมูล" description="คุณต้องลบข้อมูลหรือไม่?">
                {permTimeSheet?.delete && <DeleteOutlined style={{ color: "red" }} />}
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];
};
