import { Table } from "ant-table-extensions";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { IDeleteTask, IPatchTask, ITask, ITaskForm, ITaskTblData, TaskColumns } from "../../../../../../interfaces/ITask";
import { message } from "antd";
import isEmpty from "is-empty";
import { requestDeleteTask } from "../../../../../../middleware/fetcher/task";
import { DropDown } from "../../../../../../interfaces/ICenter";
import { CSVLink } from "react-csv";
import { IObject } from "../../../../../../interfaces/middleware/apiHandler";
import { fetchProjectAssignEmp } from "../../../../../../middleware/fetcher/projectAccess";

type TaskInterface = {
  taskData: ITask[];
  form: any;
  setOpen: any;
  setIsEditTask: any;
  fetchApi: any;
  setOption:any
};

const TaskTab: React.FC<TaskInterface> = ({ form, taskData, setOpen, setIsEditTask, fetchApi, setOption }) => {
  const [exportTblData, setExportTblData] = useState<Array<ITaskTblData>>([]);
  const { pro_code } = useParams();

  let tblData: ITaskTblData[] = [];
  let taskTblData: ITaskTblData;
  let name = "";

  taskData.map((item: any) => {
    taskTblData = {
      Task: item.name,
      Description: item.description,
      คิดเงิน: item.use_bill,
      งบประมาณ: item.rate,
      ASSIGNEES: item.assigns?.map((name: IObject) => name.first_name + " " + name.last_name + ","),
      STATUS: item.status,
    };
    tblData.push(taskTblData);
  });

  const DeleteRecord = async (e: string, name: string) => {
    const deleteTask: IDeleteTask = {
      code: e,
    };

    await requestDeleteTask(deleteTask);
    message.success(`ลบรายการ ${name} แล้ว`);
    await fetchApi();
    return;
  };

  const EditTask = async (e: ITask) => {
    if (!isEmpty(e)) {
      const edt: ITaskForm = {
        name: e.name,
        description: e.description,
        status: e.status,
        assigns: e.assigns.map((x) => x.code),
        rate: e.rate,
        use_bill: e.use_bill,
        project: pro_code ?? "",
        code: e.code,
      };

      form.setFieldsValue(edt);
      setIsEditTask(true);
      setOpen(true);
      setOption(
        e.assigns.map((x) => {
          const selectData: DropDown = {
            value: x.code,
            label: `${x.first_name} ${x.last_name}`,
          };
          return selectData;
        })
      )
      return;
    }
  };
  return (
    <>
      <CSVLink filename={"Task.csv"} data={tblData} className="btn btn-primary" style={{ position: "absolute" }}>
        บันทึกเป็น CSV
      </CSVLink>
      <Table rowKey="code" columns={TaskColumns(EditTask, DeleteRecord)} dataSource={taskData} searchable />
    </>
  );
};

export default TaskTab;
