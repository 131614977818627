import React, { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { Button, Col, Drawer, Flex, Form, message, Row, Space, Typography, Modal } from "antd";
import _ from "lodash";
import { IShift, IShiftTblData, ShiftColumns } from "../interfaces/IShift";
import { Table } from "ant-table-extensions";
import { PlusOutlined } from "@ant-design/icons";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { FormTextArea } from "../_start/layout/components/form-compoment/FormTextArea";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EStatusColumn } from "../interfaces/ICenter";
import { FormTimePicker } from "../_start/layout/components/form-compoment/FormTimePicker";
import { fetchShift, requestCreateShift, requestDeleteShift, requestUpdateShift } from "../middleware/fetcher/shift";
import isEmpty from "is-empty";
import { IObject } from "../interfaces/middleware/apiHandler";
import { mappingObjDate } from "../utils/mappingObjDate";
import { FormCheckbox } from "../_start/layout/components/form-compoment/FormCheckbox";
import { CSVLink } from "react-csv";
const { confirm } = Modal;
const Shift: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [shiftData, setShiftData] = useState<Array<IShift>>([]);
  const [exportTblData, setExportTblData] = useState<Array<IShiftTblData>>([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [statusValue, setStatusValue] = useState<string>(EStatusColumn.ACTIVE);
  const [form] = Form.useForm();
  const permShift = useMemo(() => permissions?.shift?.permission_action || {}, [permissions]);
  useEffect(() => {
    setMenuSelectedKey("3_4");
    fetchAPI();
    shiftTblData();
  }, []);

  const fetchAPI = async () => {
    const shiftTemp: IObject = await fetchShift();
    if (!isEmpty(shiftTemp?.data)) {
      const resultShift = shiftTemp?.data.map((itemShift: IObject) => {
        return mappingObjDate(itemShift);
      });
      setShiftData(resultShift);
    }
  };

  const shiftTblData = async () => {
    const shiftTemp: IObject = await fetchShift();
    const resultShift = shiftTemp?.data.map((itemShift: IObject) => {
      return mappingObjDate(itemShift);
    });
    console.log("resultShift", resultShift, 111);
    let tblData: IShiftTblData[] = [];
    let shiftTblData: IShiftTblData;

    resultShift.map((item: any) => {
      let a: any = new Date(item.sTimeStart);
      let b: any = new Date(item.sTimeEnd);
      const hour = Math.abs(b - a) / 36e5 + " Hr.";

      let breakTxt: string = "";
      let timeTxt: string = "";
      let dayTxt: string = "";
      timeTxt = item.sTimeStart.format("HH:mm") + " - " + item.sTimeEnd.format("HH:mm");
      if (item.sBreak1Start !== null) {
        breakTxt += `${item.sBreak1Start.format("HH:mm")} - ${item.sBreak1End?.format("HH:mm")}`;
      }

      if (item.sBreak2Start !== null) {
        breakTxt += `\n${item.sBreak2Start.format("HH:mm")} - ${item.sBreak2End?.format("HH:mm")}`;
      }

      if (item.sBreak3Start !== null) {
        breakTxt += `\n${item.sBreak3Start.format("HH:mm")} - ${item.sBreak3End?.format("HH:mm")}`;
      }

      if (item.sSunday) {
        dayTxt += "Sunday,";
      }

      if (item.sMonday) {
        dayTxt += "Monday,";
      }

      if (item.sTuesday) {
        dayTxt += "Tuesday,";
      }

      if (item.sWednesday) {
        dayTxt += "Wednesday,";
      }

      if (item.sThursday) {
        dayTxt += "Thursday,";
      }

      if (item.sFriday) {
        dayTxt += "Friday,";
      }

      if (item.sSaturday) {
        dayTxt += "Saturday";
      }

      shiftTblData = {
        SHIFT_NAME: item.sName,
        DESCRIPTION: item.sDesc,
        TIME: timeTxt,
        BREAK: breakTxt,
        HOUR: hour,
        DAYS: dayTxt,
        STATUS: item.sStatus,
      };
      tblData.push(shiftTblData);
    });
    setExportTblData(tblData);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setIsEdit(false);
    form.resetFields();
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    confirm({
      title: `ต้องการบันทึกข้อมูลใช่หรือไม่?`,
      async onOk() {
        try {
          if (isEdit) {
            await requestUpdateShift(values);
          } else {
            await requestCreateShift(values);
          }
          setOpen(false);
          setIsEdit(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
          await fetchAPI();
        } catch (error) {
          message.error("บันทึกข้อมูลล้มเหลว!");
        }
      },
      onCancel() {},
    });
  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const DeleteRecord = async (e: string) => {
    if (isEmpty(e)) {
      message.error("ไม่พบรหัสกะ!");
    } else {
      await requestDeleteShift({ sCode: e });
      message.success("ลบกะสำเร็จ!");
      await fetchAPI();
    }
  };

  const EditShift = (e: IObject) => {
    setIsEdit(true);
    form.setFieldsValue(e);
    setOpen(true);
  };

  const selectChange = (value: string) => {
    setStatusValue(value);
    console.log(`selected ${value}`);
  };
  return (
    <>
      <Flex key="AddBranch" align="flex-end" gap="small" vertical>
        {permShift?.add && (
          <Button type="primary" onClick={showDrawer}>
            <PlusOutlined />
            เพิ่มกะเข้างาน
          </Button>
        )}
      </Flex>
      <br />
      <Drawer
        title="New Shift"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
          <FormInput name="sCode" label="รหัสกะเข้างาน" required={true} message="กรุณาระบุรหัสกะเข้างาน" disabled={isEdit} />

          <FormInput name="sName" label="ชื่อกะเข้างาน" required={true} message="กรุณาระบุชื่อกะเข้างาน" />

          <FormTextArea name="sDesc" label="คำอธิบาย" required={false} message="คำอธิบาย" />

          <FormTimePicker name="sTimeStart" label="เวลาเข้างาน" required={true} message="กรุณาระบุเวลาเข้างาน" />

          <FormTimePicker name="sTimeEnd" label="เวลาออกงาน" required={true} message="กรุณาระบุเวลาออกงาน" />

          <Row>
            <Col span={12} style={{ paddingRight: "5px" }}>
              <FormTimePicker name="sBreak1Start" label="เริ่มพักช่วงที่ 1" required={true} message="กรุณาระบุเริ่มพักช่วงที่ 1" />
            </Col>
            <Col span={12} style={{ paddingLeft: "5px" }}>
              <FormTimePicker name="sBreak1End" label="สิ้นสุดพักช่วงที่ 1" required={true} message="กรุณาระบุสิ้นสุดพักช่วงที่ 1" />
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ paddingRight: "5px" }}>
              <FormTimePicker name="sBreak2Start" label="เริ่มพักช่วงที่ 2" message="กรุณาระบุเริ่มพักช่วงที่ 2" />
            </Col>
            <Col span={12} style={{ paddingLeft: "5px" }}>
              <FormTimePicker name="sBreak2End" label="สิ้นสุดพักช่วงที่ 2" message="กรุณาระบุสิ้นสุดพักช่วงที่ 2" />
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ paddingRight: "5px" }}>
              <FormTimePicker name="sBreak3Start" label="เริ่มพักช่วงที่ 3" message="กรุณาระบุเริ่มพักช่วงที่ 3" />
            </Col>
            <Col span={12} style={{ paddingLeft: "5px" }}>
              <FormTimePicker name="sBreak3End" label="สิ้นสุดพักช่วงที่ 3" message="กรุณาระบุสิ้นสุดพักช่วงที่ 3" />
            </Col>
          </Row>

          <FormSelect name="sStatus" label="สถานะ" required={true} message="กรุณาเลือกสถานะ" defaultValue={EStatusColumn.ACTIVE} onChange={selectChange} options={DropDown} />
          <Typography.Title level={5}>วันทำงาน</Typography.Title>
          <Row>
            <Space direction="vertical" size={10}>
              <FormCheckbox name="sSunday" label="วันอาทิตย์" />
              <FormCheckbox name="sMonday" label="วันจันทร์" />
              <FormCheckbox name="sTuesday" label="วันอังคาร" />
              <FormCheckbox name="sWednesday" label="วันพุธ" />
              <FormCheckbox name="sThursday" label="วันพฤหัสบดี" />
              <FormCheckbox name="sFriday" label="วันศุกร์" />
              <FormCheckbox name="sSaturday" label="วันเสาร์" />
            </Space>
          </Row>
          <Space style={{ marginTop: "30px" }}>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>

      <CSVLink filename={"shift.csv"} data={exportTblData} className="btn btn-primary" style={{ position: "absolute" }}>
        บันทึกเป็น CSV
      </CSVLink>
      <Table rowKey="sCode" columns={ShiftColumns(EditShift, DeleteRecord, permShift)} dataSource={shiftData} searchable />
    </>
  );
};

export default Shift;
