import React, { useEffect, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import {
  Col,
  Row,
  Table,
  DatePicker,
  Segmented,
  Select,
  Button,
  Tag,
  Typography,
  Statistic,
  Card,
  Avatar,
  Layout,
} from "antd";
import type { TableColumnsType, TableProps, MenuProps, StatisticProps } from "antd";
import { CSVLink } from "react-csv";
import {
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import CountUp from "react-countup";
const { Header, Content, Footer, Sider } = Layout;
const { RangePicker } = DatePicker;
const { Text } = Typography;

interface DataType {
  code: string;
  name: string;
  desc: string | null;
  taskName: string;
  taskDepart: string | null;
  billable_rate: string;
  user: string;
  status: string;
  time: string;
  date: string;
  duration: string;
}

const DailyReport: React.FC = () => {
  const { setMenuSelectedKey } = useAppDataContext();
  const [team, setTeam] = useState("Team");
  useEffect(() => {
    setMenuSelectedKey("2_1");
  }, []);

  const columns: TableColumnsType<DataType> = [
    {
      title: "PROJECT",
      key: "name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <>
          <Text underline>{record.name}</Text>
          <br />
          <Text type="secondary">{record.desc}</Text>
        </>
      ),
    },
    {
      title: "TASK",
      key: "task",
      dataIndex: "task",
      render: (_: any, record: any) => (
        <>
          <Tag color="#2db7f5">{record.taskDepart}</Tag>
          <Text underline>{record.taskName}</Text>
        </>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "billable_rate",
      key: "billable_rate",
    },
    {
      title: "USER",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "TIME",
      key: "date",
      dataIndex: "time",
      render: (_: any, record: any) => (
        <>
          <Text>{record.time}</Text>
          <br />
          <Text type="secondary">{record.date}</Text>
        </>
      ),
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      key: "duration",
      render: (_: any, record: any) => (
        <Tag icon={<ClockCircleOutlined />} color="default" style={{fontSize:14}}>
          {record.duration}
        </Tag>
      ),
    },
  ];

  const data: DataType[] = [
    {
      code: "001",
      name: "patrol_ห้ามดีลีท ABCDEF OOO",
      desc: "test update description",
      taskName: "Gacha 1",
      taskDepart: "IT",
      billable_rate:"1,000",
      user: "Pornchai Akewaranugulsiri",
      time: "08:00 17:00",
      date: "30/10/2527",
      status: "active",
      duration: "08:00:00",
    },
    {
      code: "002",
      name: "patrol",
      desc: "Todo list patrol",
      taskName: "Task002",
      taskDepart: "HR",
      billable_rate:"2,000",
      user: "Pornchai Akewaranugulsiri",
      time: "08:00 22:00",
      date: "29/10/2527",
      status: "active",
      duration: "12:00:00",
    },
  ];

  const onChange: TableProps<DataType>["onChange"] = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <a onClick={() => setTeam("Team 1")}>Team 1</a>,
    },
    {
      key: "2",
      label: <a onClick={() => setTeam("Team 2")}>Team 2</a>,
    },
    {
      key: "3",
      label: <a onClick={() => setTeam("Team 3")}>Team 3</a>,
    },
  ];

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator="," />
  );
  return (
    <>
      <Header
        style={{
          padding: "0 20px",
          background: "#ffffff",
          border: "solid 1px #f0f0f0",
          borderRadius: 8,
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 15,
        }}
      >
        <RangePicker style={{ height: 33 }} />
      </Header>
      <div style={{ padding: 20, margin: "20px 0", border: "solid 1px #f0f0f0", borderRadius:10 }}>
        <Row gutter={24}>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Team"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Team 1",
                },
                {
                  value: "2",
                  label: "Team2 ",
                },
                {
                  value: "3",
                  label: "Team 3",
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Client"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Client 1",
                },
                {
                  value: "2",
                  label: "Client 2",
                },
                {
                  value: "3",
                  label: "Client 3",
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Project"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Project 1",
                },
                {
                  value: "2",
                  label: "Project 2",
                },
                {
                  value: "3",
                  label: "Project 3",
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: 20 }}>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Task"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Task 1",
                },
                {
                  value: "2",
                  label: "Task 2",
                },
                {
                  value: "3",
                  label: "Task 3",
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Tag"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Tag 1",
                },
                {
                  value: "2",
                  label: "Tag 2",
                },
                {
                  value: "3",
                  label: "Tag 3",
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Status"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Active",
                },
                {
                  value: "2",
                  label: "Inactive",
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={24} style={{ justifyContent: "center", marginTop: 30 }}>
          <Button type="primary" shape="round" icon={<FilterOutlined />} size={"large"}>
            APPLY FILTER
          </Button>
        </Row>
      </div>
      <Row gutter={24}>
        <Col span={12} style={{ display: "flex" }}>
          <CSVLink
            filename={"Project.csv"}
            data={data}
            className="btn btn-primary"
            style={{ alignSelf: "flex-end" }}
          >
            บันทึกเป็น CSV
          </CSVLink>
        </Col>
        <Col
          span={12}
          style={{ display: "flex", justifyContent: "end", padding: 20, borderRadius: 5 }}
        >
            <Statistic title="Total" value={"20:00 Hr."} precision={2} style={{width:150, border:"solid 1px #f0f0f0", borderRadius:10, padding:10, boxShadow: "0 3px 5px rgba(0,10,10,.05)"}} />
            <Statistic title="Billable" value={"20:00 Hr."} precision={2} style={{width:150, border:"solid 1px #f0f0f0", borderRadius:10, padding:10, margin:"0 10px", boxShadow: "0 3px 5px rgba(0,10,10,.05)"}} />
            <Statistic title="Amount" value={3000} precision={2} formatter={formatter} style={{width:150, border:"solid 1px #f0f0f0", borderRadius:10, padding:10, boxShadow: "0 3px 5px rgba(0,10,10,.05)"}} />
        </Col>
      </Row>

      <Table<DataType>
        columns={columns}
        dataSource={data}
        onChange={onChange}
        style={{ marginTop: 10 }}
      />
    </>
  );
};

export default DailyReport;
