import React, { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { Button, Space, Flex, Drawer, Form, message, Modal } from "antd";
import { columns, IBranch, IBranchTblData, IRequestCreateBranch, IRequestUpdateBranch, mockData } from "../interfaces/IBranch";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { FormTextArea } from "../_start/layout/components/form-compoment/FormTextArea";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EStatusColumn } from "../interfaces/ICenter";
import { Table } from "ant-table-extensions";
import { fetchBranch, fetchBranchCreate, fetchBranchDelete, fetchBranchEdit } from "../middleware/fetcher/branch";
import { CSVLink } from "react-csv";
const { confirm } = Modal;

const Branch: React.FC = () => {
  const [form] = Form.useForm();
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [open, setOpen] = useState(false);
  const [brnData, setBRNData] = useState<Array<IBranch>>([]);
  const [exportTblData, setExportTblData] = useState<Array<IBranchTblData>>([]);
  const [event, setEvent] = useState("");
  const [inputDisable, setInputDisable] = useState<boolean>();
  const permBranch = useMemo(() => permissions?.branch?.permission_action || {}, [permissions]);
  useEffect(() => {
    setMenuSelectedKey("3_3");
    getBranchList();
    branchTblData();
  }, []);

  const getBranchList = async () => {
    const branchList: any = await fetchBranch("all");
    setBRNData(branchList.data.branch);
  }

  const branchTblData = async () => {
    const branchList: any = await fetchBranch("all");
    let tblData: IBranchTblData[] = [];
    let branchTblData: IBranchTblData;

    branchList.data.branch.map((item:any) => {
      branchTblData = {
        รหัสสาขา: item.brn_code,
        สาขา: item.brn_name,
        รายละเอียด: item.brn_desc,
        สถานะ: item.brn_status
      }
      tblData.push(branchTblData);
    });
    setExportTblData(tblData);
  }

  const showDrawer = () => {
    setEvent("add");
    setInputDisable(false);
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFinish = async () => {
    const dt: IBranch = { ...form.getFieldsValue() };
    let title = "";
    if (event == "add") {
      title = "ต้องการบันทึกข้อมูล";
    } else {
      title = "ต้องการบันทึกข้อมูล";
    }

    confirm({
      title: `${title} ${dt.brn_name}ใช่หรือไม่?`,
      async onOk() {
        try {
          const code: string = dt.brn_code;
          if(typeof dt.brn_desc !== "string") {
            dt.brn_desc = "";
          }
          if (event == "add") {
            const dataCreate: IRequestCreateBranch = {
              brn_code: dt.brn_code,
              brn_name: dt.brn_name,
              brn_desc: dt.brn_desc,
              brn_status: dt.brn_status
            }
            await fetchBranchCreate(dataCreate);
          } else {
            const dataEdit: IRequestUpdateBranch = {
              brn_name: dt.brn_name,
              brn_desc: dt.brn_desc,
              brn_status: dt.brn_status
            }
            await fetchBranchEdit(code, dataEdit);
          }

          const branchList: any = await fetchBranch("all");
          setBRNData(branchList.data.branch);

          setOpen(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
        } catch (e) {
          return console.log('Oops errors!');
        }
      },
      onCancel() { },
    });


  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const DeleteRecord = async (code: string, name: string) => {
    await fetchBranchDelete(code);
    const branchList: any = await fetchBranch("all");
    message.success(`ลบรายการ ${name} แล้ว`);
    setBRNData(branchList.data.branch);
  };

  const EditBranch = (e: string) => {
    setEvent("edit");
    setInputDisable(true);
    const ed: any = _.filter(brnData, (v, i) => v.brn_code === e);
    const edt: IBranch = { ...ed[0] };
    form.setFieldsValue(edt);
    setOpen(true);
  };

  const selectChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Flex key="AddBranch" align="flex-end" gap="small" vertical>
        {permBranch?.add && <Button type="primary" onClick={showDrawer}>
          <PlusOutlined />
          เพิ่มสาขา
        </Button>}
      </Flex>
      <br />
      <Drawer
        title="บันทึกสาขา"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <FormInput
            name="brn_code"
            label="รหัสสาขา"
            required={true}
            message="กรุณาระบุรหัสสาขา"
            disabled={inputDisable}
          />

          <FormInput
            name="brn_name"
            label="ชื่อสาขา"
            required={true}
            message="กรุณาระบุชื่อสาขา"
          />

          <FormTextArea
            name="brn_desc"
            label="คำอธิบาย"
            required={false}
            message="กรุณาระบุคำอธิบาย"
          />

          <FormSelect
            name="brn_status"
            label="สถานะ"
            required={true}
            message="กรุณาเลือกสถานะ"
            defaultValue={EStatusColumn.ACTIVE}
            onChange={selectChange}
            options={DropDown}
          />

          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>
      <CSVLink filename={"Branch.csv"} data={exportTblData} className="btn btn-primary" style={{position:"absolute"}}>
        บันทึกเป็น CSV
      </CSVLink>
      <Table
        rowKey="brn_code"
        columns={columns(EditBranch, DeleteRecord, permBranch)}
        dataSource={brnData}
        searchable
      />
    </>
  );
};

export default Branch;
