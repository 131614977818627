import React, { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { Button, Space, Flex, Drawer, Form, message, Select, Modal } from "antd";
import { Table } from "ant-table-extensions";
import { columns, IEmployee, IEmpTblData, IRequestCreateEmployee, IRequestUpdateEmployee, prefixSelector, selectTreeBranchDept } from "../interfaces/IEmployee";
import _ from "lodash";
import { PlusOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { FormInput, AddOnBeforeInput } from "../_start/layout/components/form-compoment/FormInput";
import { FormSelect, FormSelectTree } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EEvent, EStatusColumn } from "../interfaces/ICenter";
import { deleteEmployee, fetchBranchDeptList, fetchEmployee, fetchEmployeeCreate, fetchEmployeeEdit } from "../middleware/fetcher/employee";
import { IObject } from "../interfaces/middleware/apiHandler";
import { CSVLink } from "react-csv";
import { fetchRolePermission } from "../middleware/fetcher/role";
import { fetchShift } from "../middleware/fetcher/shift";

const { confirm } = Modal;
const Employee: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [open, setOpen] = useState(false);
  const [empData, setEmpData] = useState<Array<IEmployee>>([]);
  const [exportTblData, setExportTblData] = useState<Array<IEmpTblData>>([]);
  const [roleSelect, setRoleSelect] = useState<Array<DropDown>>();
  const [shiftSelect, setShiftSelect] = useState<Array<DropDown>>();
  const [branchDept, setBranchDept] = useState([]);
  const [event, setEvent] = useState("");
  const [inputDisable, setInputDisable] = useState<boolean>();
  const [statusValue, setStatusValue] = useState<string>(EStatusColumn.ACTIVE);
  const [shiftValue, setShiftValue] = useState<string>();
  const permEmployee = useMemo(() => permissions?.employee?.permission_action || {}, [permissions]);
  useEffect(() => {
    setMenuSelectedKey("3_2");
    getEmpList();
    empTblData();
    RoleList();
    getShift();
  }, []);

  const RoleList = async () => {
    let roleSelect: DropDown;
    let arrRole: DropDown[] = [];
    const roleList: IObject = await fetchRolePermission("all");
    roleList.data.map((item: any) => {
      roleSelect = {
        value: item.role_code,
        label: item.role_name,
      };
      if(item.role_status === EStatusColumn.ACTIVE)
      arrRole.push(roleSelect);
    });
    setRoleSelect(arrRole);
  };

  const getShift = async () => {
    let shiftSelect: DropDown;
    let arrShift: DropDown[] = [];
    const shiftList: IObject = await fetchShift();
    shiftList.data.map((item: any) => {
      shiftSelect = {
        value: item.sCode,
        label: item.sName,
      };

      if(item.sStatus === EStatusColumn.ACTIVE)
        arrShift.push(shiftSelect);

    });
    setShiftSelect(arrShift);
  };

  const getEmpList = async () => {
    const getEmpData: any = await fetchEmployee("all");
    const branchDeptList: any = await fetchBranchDeptList();
    setBranchDept(branchDeptList.data);
    for (let i = 0; i < getEmpData.data.employee.length; i++) {
      const employee = getEmpData.data.employee[i];
      for (let j = 0; j < branchDeptList.data.length; j++) {
        for (let k = 0; k < branchDeptList.data[j].department.length; k++) {
          const deptCode = branchDeptList.data[j].department[k].dept_code;
          if (employee.dept_code === deptCode) {
            getEmpData.data.employee[i].brn_code = branchDeptList.data[j].brn_code;
            getEmpData.data.employee[i].brn_name = branchDeptList.data[j].brn_name;
            getEmpData.data.employee[i].dept_name = branchDeptList.data[j].department[k].dept_name;
          }
        }
      }
    }
    setEmpData(getEmpData.data.employee);
  };

  const empTblData = async () => {
    const getEmpData: any = await fetchEmployee("all");
    const branchDeptList: any = await fetchBranchDeptList();
    setBranchDept(branchDeptList.data);
    for (let i = 0; i < getEmpData.data.employee.length; i++) {
      const employee = getEmpData.data.employee[i];
      for (let j = 0; j < branchDeptList.data.length; j++) {
        for (let k = 0; k < branchDeptList.data[j].department.length; k++) {
          const deptCode = branchDeptList.data[j].department[k].dept_code;
          if (employee.dept_code === deptCode) {
            getEmpData.data.employee[i].brn_code = branchDeptList.data[j].brn_code;
            getEmpData.data.employee[i].brn_name = branchDeptList.data[j].brn_name;
            getEmpData.data.employee[i].dept_name = branchDeptList.data[j].department[k].dept_name;
          }
        }
      }
    }

    let tblData: IEmpTblData[] = [];
    let empTblData: IEmpTblData;

    getEmpData.data.employee.map((item: any) => {
      empTblData = {
        รหัสสาขา: item.brn_code,
        รหัสหน่วยงาน: item.dept_code,
        รหัสพนักงาน: item.emp_code,
        ชื่อนามสกุล: item.emp_first_name + " " + item.emp_last_name,
        อีเมล์: item.emp_email,
        โทรศัพท์: `'${item.emp_phone}`,
        สถานะ: item.emp_status,
      };
      tblData.push(empTblData);
    });
    setExportTblData(tblData);
  };

  const showDrawer = () => {
    setEvent(EEvent.ADD);
    setInputDisable(false);
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const [form] = Form.useForm();

  const onFinish = () => {
    const dt: IEmployee = { ...form.getFieldsValue() };
    if (event === EEvent.EDIT) {
      confirm({
        title: `ต้องการบันทึกข้อมูลใช่หรือไม่?`,
        async onOk() {
          try {
            const empCode: string = dt.emp_code;
            const dataUpdate: IRequestUpdateEmployee = {
              first_name: dt.emp_first_name,
              last_name: dt.emp_last_name,
              salary: dt.emp_salary,
              phone: dt.emp_phone,
              status: statusValue,
              role_code: dt.role_code,
              dept_code: treeValue,
              email: dt.emp_email,
              shift_code: shiftValue,
            };
            await fetchEmployeeEdit(empCode, dataUpdate);
            getEmpList();
            setOpen(false);
            message.success("บันทึกข้อมูลสำเร็จ!");
            form.resetFields();
          } catch (err) {
            return console.log("Oops errors!");
          }
        },
        onCancel() {},
      });
    }
    if (event === EEvent.ADD) {
      confirm({
        title: `ต้องการบันทึกข้อมูลใช่หรือไม่`,
        async onOk() {
          try {
            const dataCreate: IRequestCreateEmployee = {
              first_name: dt.emp_first_name,
              last_name: dt.emp_last_name,
              salary: Number(dt.emp_salary),
              phone: dt.emp_phone,
              role_code: dt.role_code,
              dept_code: treeValue,
              email: dt.emp_email,
              code: dt.emp_code,
              username: dt.emp_username,
              password: dt.emp_password,
              status: statusValue,
              shift_code: shiftValue,
            };
            await fetchEmployeeCreate(dataCreate);
            getEmpList();
            setOpen(false);
            message.success("บันทึกข้อมูลสำเร็จ!");
            form.resetFields();
          } catch (err) {
            return console.log("Oops errors!");
          }
        },
        onCancel() {},
      });
    }
  };
  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const selectChange = (value: string) => {
    console.log(`selected ${value}`);
    //setStatusValue(value);
  };

  const selectStatusChange = (value: string) => {
    console.log(`selected ${value}`);
    setStatusValue(value);
  };

  const selectShiftChange = (value: string) => {
    console.log(`selected ${value}`);
    setShiftValue(value);
  };

  const [treeValue, setTreeValue] = useState<string>();
  const SelectTreeChange = (treeValue: string) => {
    console.log(`selected ${treeValue}`);
    setTreeValue(treeValue);
  };

  const DeleteRecord = async (e: string) => {
    const empCode = e.split(" ")[0];
    await deleteEmployee(empCode);
    message.success(`ลบรายการพนักงาน ${e} แล้ว`);
    getEmpList();
  };

  const EditEmp = (e: any) => {
    setEvent(EEvent.EDIT);
    setInputDisable(true);
    const ed: any = _.filter(empData, (v) => v.emp_code === e.emp_code);
    const edt: IEmployee | any = { ...ed[0] };
    form.setFieldsValue(edt);
    setOpen(true);
    setTreeValue(e.dept_code);
    setShiftValue(e.shift_code);
  };

  return (
    <>
      <Flex key="AddEmp" align="flex-end" gap="small" vertical>
        {permEmployee?.add && (
          <Button type="primary" onClick={showDrawer}>
            <PlusOutlined />
            เพิ่มพนักงาน
          </Button>
        )}
      </Flex>
      <br />
      <Drawer
        title="บันทึกพนักงาน"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
          <FormInput name="emp_code" label="รหัสพนักงาน" required={true} message="กรุณาระบุรหัสพนักงาน" disabled={inputDisable} />

          <FormInput name="emp_first_name" label="ชื่อ" required={true} message="กรุณาระบุชื่อ" />

          <FormInput name="emp_last_name" label="นามสกุล" required={true} message="กรุณาระบุนามสกุล" />

          <FormSelectTree name="dept_name" label="สังกัด" required={true} message="กรุณาเลือกสังกัด" onChange={SelectTreeChange} options={selectTreeBranchDept(branchDept)} defaultValue="" />

          <FormInput name="emp_salary" label="เงินเดือน" inputType="number" />

          <FormInput name="emp_email" label="อีเมล์" type="email" message="รูปแบบอีเมล์ไม่ถูกต้อง!" />

          <AddOnBeforeInput name="emp_phone" label="โทรศัพท์" addonBefore={prefixSelector} />

          <FormInput name="emp_username" label="บัญชีผู้ใช้งาน" prefix={<UserOutlined className="site-form-item-icon" />} disabled={inputDisable} />

          <FormInput name="emp_password" label="รหัสผ่าน" inputType="password" prefix={<LockOutlined className="site-form-item-icon" />} disabled={inputDisable} />

          <FormSelect name="shift_code" label="กะการทำงาน" required={true} message="กรุณาเลือกกะการทำงาน" defaultValue={shiftValue} onChange={selectShiftChange} options={shiftSelect} />

          <FormSelect name="role_code" label="สิทธิ์" required={true} message="กรุณาเลือกสิทธิ์" defaultValue="" onChange={selectChange} options={roleSelect} />

          <FormSelect name="emp_status" label="สถานะ" required={false} message="กรุณาเลือกสถานะ" defaultValue={EStatusColumn.ACTIVE} onChange={selectStatusChange} options={DropDown} />

          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>
      <CSVLink filename={"Employee.csv"} data={exportTblData} className="btn btn-primary" style={{ position: "absolute" }}>
      บันทึกเป็น CSV
      </CSVLink>
      <Table columns={columns(EditEmp, DeleteRecord, permEmployee)} dataSource={empData} searchable />
    </>
  );
};

export default Employee;

// function getRolePermissionList() {
//   throw new Error("Function not implemented.");
// }
