import { Card, Checkbox, Form, List, Typography } from "antd";
const { Paragraph } = Typography;

type InputProps = {
  name: string;
  title: string;
  paragraph: string;
  dataSource: string[];
  onClick?: any;
};

const CardListCheckbox: React.FC<InputProps> = ({
  name,
  title,
  paragraph,
  dataSource,
  onClick,
}) => {
  return (
    <Card>
      <Typography.Title level={4} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
      <Paragraph>{paragraph}</Paragraph>
      <List
        bordered
        dataSource={dataSource}
        renderItem={(item: any) => {
          let renderTemp = []
          for (const key in item) {
            renderTemp.push(<List.Item>
              <Form.Item name={[name, key]} valuePropName="checked" initialValue={item[key]}>
                <Checkbox onClick={onClick} defaultChecked={item[key]}>{key}</Checkbox>
              </Form.Item>
            </List.Item>)
          }
          return renderTemp
        }}
      />
    </Card>
  );
};

export { CardListCheckbox };
