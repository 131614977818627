import { Link } from "react-router-dom";
import { DropDown, TagStatus } from "./ICenter";
import { Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Text } = Typography;

export interface IProject {
  pro_code: string;
  pro_name: string;
  pro_des: string;
  client: string;
  status: string;
}

export interface IProjectTblData {
  PROJECT: string;
  DESCRIPTION: string;
  คิดเงิน: string;
  งบประมาณ: string;
  CLIENT: string;
  STATUS: string;
}

export interface IRequestCreateProject {
  code: string;
  name: string;
  desc: string | null;
  client_code: string;
  status: string;
  access_is_public: boolean;
  billable_rate: number | null;
  billable_is_default: boolean;
}

export interface IRequestEditProject extends Omit<IRequestCreateProject, "code"> {}

export interface IProjectForm {
  pro_code: string;
  pro_name: string;
  pro_desc: string;
  client: string;
  status: string;
  is_public: boolean;
  pro_billing: boolean;
  pro_budget: number;
}

// Data mockup
export let mockData: IProject[] = [
  {
    pro_code: "PRO001",
    pro_name: "Travel",
    pro_des: "Choose from wide range of travel accessories from popular brands",
    client: "รามอินทรา",
    status: "1",
  },
  {
    pro_code: "PRO002",
    pro_name: "Beauty & Personal Care",
    pro_des: "Choose from wide range of travel accessories from popular brands",
    client: "greenville center",
    status: "2",
  },
];

// mockup Client
export let mockClient: DropDown[] = [
  { value: "รามอินทรา", label: "รามอินทรา" },
  { value: "กาญจนบุรี", label: "กาญจนบุรี" },
  { value: "นครปฐม", label: "นครปฐม" },
  { value: "greenville center", label: "greenville center" },
  { value: "เซเนสเทีย", label: "เซเนสเทีย" },
];

export interface IPutProjectAssign {
  project_code: string;
  op: string;
  emp_code: string;
}

export interface IProjectAccessSelect {
  code: string;
  name: string;
}

// Set columns for table
export const columns = (EditProject: any, DeleteRecord: any, permProject: any) => {
  return [
    {
      title: "โครงการ",
      key: "name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <>
          <Link to={`/Task/${record.code}`} title="Task">
            <Text underline >
              {record.name}
            </Text>
            <br />
            <Text type="secondary">{record.desc}</Text>
          </Link>
        </>
      ),
    },
    {
      title: "สถานะการเผยแพร่",
      dataIndex: "access_is_public",
      key: "access_is_public",
      render: (_: any, record: any) => (
        <>
          <Tag bordered={false} color={record.access_is_public ? "green" : "blue"}>
            {record.access_is_public ? "Public" : "Private"}
          </Tag>
        </>
      ),
    },
    {
      title: "คิดเงิน",
      dataIndex: "billable_is_default",
      key: "billable_is_default",
      render: (_: any, record: any) => <>{record.billable_is_default ? "Yes" : "No"}</>,
    },
    {
      title: "งบประมาณ",
      dataIndex: "billable_rate",
      key: "billable_rate",
    },
    {
      title: "สาขา",
      dataIndex: "client_code",
      key: "client_code",
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => TagStatus(record.status, record.pro_code),
    },
    {
      title: "ดำเนินการ",
      key: "pro_code",
      render: (_: any, record: any) => (
        <Space size="middle">
          {permProject?.edit && <EditOutlined style={{ cursor: "pointer", color: "blue" }} onClick={() => EditProject(record.code)} />}

          <Popconfirm onConfirm={() => DeleteRecord(record.code, record.name)} title="ยืนยันการลบข้อมูล" description="คุณต้องลบข้อมูลหรือไม่?">
            {permProject?.delete && <DeleteOutlined style={{ color: "red" }} />}
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
