import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuTheme } from "antd";
import Cookies from "js-cookie";
import isEmpty from "is-empty";
import { requestProtected } from "../../../middleware/fetcher/auth";
import { IObject } from "../../../interfaces/middleware/apiHandler";

type Props = {
  children: any;
};

export const useAppDataContext = () => {
  return useContext(AppContext);
};

export interface AppDataContext {
  menuSelectedKey: string;
  menuTheme: MenuTheme;
  setMenuTheme: (menuTheme: MenuTheme) => void;
  setMenuSelectedKey: (e: any) => void;
  permissions: IObject;
  user:IObject;
  isMobile:boolean;
  setIsMobile:(e:boolean)=>void;
  deviceWidth?: number;
  setDeviceWidth:(e:number)=>void;
}

const AppContext = createContext<AppDataContext>({
  menuSelectedKey: "1_1",
  menuTheme: "light",
  setMenuTheme: (menuTheme: MenuTheme) => {},
  setMenuSelectedKey: (e: any) => {},
  permissions: {}, //
  user: {},
  isMobile:false,
  setIsMobile:(e:boolean)=>{},
  deviceWidth: 0,
  setDeviceWidth:(e:number)=>{},
});

const AppProvider: React.FC<Props> = ({ children }) => {
  const [menuTheme, _setMenuTheme] = useState<MenuTheme>("light");
  const [menuSelectedKey, _setMenuSelectedKey] = useState<string>("1");
  const [permissions, setPermissions] = useState<IObject>({});
  const [user, setUser] = useState({});
  const [isMobile,setIsMobile] = useState<boolean>(false)
  const [deviceWidth, setDeviceWidth] = useState<number>()

  const navigate = useNavigate();

  const setMenuTheme = (menuTheme: MenuTheme) => _setMenuTheme(menuTheme);
  const setMenuSelectedKey = (e: any) => _setMenuSelectedKey(e);

  useEffect(() => {
    const cookie = Cookies.get("access_token");
    if (isEmpty(cookie)) {
      navigate("/Login");
    } else {
      if (isEmpty(permissions)) {
        fetchPerm();
      }
    }
    return () => {};
  }, [menuSelectedKey]);

  const fetchPerm = async () => {
    try {
      const perm = await requestProtected();
      setPermissions(perm?.data.permission as IObject);
      setUser(perm?.data.user as IObject);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      setPermissions({});
      setUser({});
    }
  };

  const value: AppDataContext = {
    menuSelectedKey,
    menuTheme,
    setMenuTheme,
    setMenuSelectedKey,
    permissions,
    user,
    isMobile,
    setIsMobile,
    deviceWidth,
    setDeviceWidth
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
