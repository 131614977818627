// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* width */
::-webkit-scrollbar {
 width: 5px;
 background: transparent;
 border-radius: 10px;
 box-shadow: none;
 border: none;
}

/* Track */
::-webkit-scrollbar-track {
 border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
 background: #d9d9d9;
 border-radius: 10px;
 width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
 background: #d9d9d9;
}

.ant-menu-inline {
 border-inline-end: 1px
  solid
  rgba(
   5,
   5,
   5,
   0
  ) !important;
}

.calendar-event {
 font-size: 14px !important;
 font-weight: 500 !important;
 padding: 3px !important;
}

.calendar-event-danger {
 background-color: #ffe0db !important;
 border-color: rgba(
  255,
  62,
  29,
  0.15
 ) !important;
}

.calendar-event-primary {
 background-color: #e7e7ff !important;
 border-color: rgba(
  105,
  108,
  255,
  0.15
 ) !important;
}

.calendar-event-warning {
 background-color: #fff2d6 !important;
 border-color: rgba(
  255,
  171,
  0,
  0.15
 ) !important;
}

.calendar-event-info {
 background-color: #d7f5fc !important;
 border-color: rgba(
  3,
  195,
  236,
  0.15
 ) !important;
}

.calendar-event-success {
 background-color: #e8fadf !important;
 border-color: rgba(
  113,
  221,
  55,
  0.15
 ) !important;
}
`, "",{"version":3,"sources":["webpack://./src/_start/layout/components/custom-style.css"],"names":[],"mappings":"AAAA,UAAU;AACV;CACC,UAAU;CACV,uBAAuB;CACvB,mBAAmB;CACnB,gBAAgB;CAChB,YAAY;AACb;;AAEA,UAAU;AACV;CACC,mBAAmB;AACpB;;AAEA,WAAW;AACX;CACC,mBAAmB;CACnB,mBAAmB;CACnB,UAAU;AACX;;AAEA,oBAAoB;AACpB;CACC,mBAAmB;AACpB;;AAEA;CACC;;;;;;;cAOa;AACd;;AAEA;CACC,0BAA0B;CAC1B,2BAA2B;CAC3B,uBAAuB;AACxB;;AAEA;CACC,oCAAoC;CACpC;;;;;aAKY;AACb;;AAEA;CACC,oCAAoC;CACpC;;;;;aAKY;AACb;;AAEA;CACC,oCAAoC;CACpC;;;;;aAKY;AACb;;AAEA;CACC,oCAAoC;CACpC;;;;;aAKY;AACb;;AAEA;CACC,oCAAoC;CACpC;;;;;aAKY;AACb","sourcesContent":["/* width */\n::-webkit-scrollbar {\n width: 5px;\n background: transparent;\n border-radius: 10px;\n box-shadow: none;\n border: none;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n border-radius: 10px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n background: #d9d9d9;\n border-radius: 10px;\n width: 8px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n background: #d9d9d9;\n}\n\n.ant-menu-inline {\n border-inline-end: 1px\n  solid\n  rgba(\n   5,\n   5,\n   5,\n   0\n  ) !important;\n}\n\n.calendar-event {\n font-size: 14px !important;\n font-weight: 500 !important;\n padding: 3px !important;\n}\n\n.calendar-event-danger {\n background-color: #ffe0db !important;\n border-color: rgba(\n  255,\n  62,\n  29,\n  0.15\n ) !important;\n}\n\n.calendar-event-primary {\n background-color: #e7e7ff !important;\n border-color: rgba(\n  105,\n  108,\n  255,\n  0.15\n ) !important;\n}\n\n.calendar-event-warning {\n background-color: #fff2d6 !important;\n border-color: rgba(\n  255,\n  171,\n  0,\n  0.15\n ) !important;\n}\n\n.calendar-event-info {\n background-color: #d7f5fc !important;\n border-color: rgba(\n  3,\n  195,\n  236,\n  0.15\n ) !important;\n}\n\n.calendar-event-success {\n background-color: #e8fadf !important;\n border-color: rgba(\n  113,\n  221,\n  55,\n  0.15\n ) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
