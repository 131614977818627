import React, { CSSProperties, useEffect, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { CalendarEvent } from "../_start/layout/components/CalendarEvent";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { Button, Collapse, CollapseProps, Divider, Drawer, Flex, Form, message, Modal, Space, Tag, theme, Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EStatusColumn } from "../interfaces/ICenter";
import { SearchOutlined, PlusOutlined, CaretRightOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { IShift } from "../interfaces/IShift";
import { TimeSheetCreate, TimeSheetFilter, TimeSheetForm, TimeSheetInit, TimeSheetUpdate } from "../interfaces/ITimeSheet";
import { fetchCalendar, fetchProjectSelect, fetchTaskSelect, fetchTimeSheetCreate, fetchTimesheetFilter, fetchTimeSheetInit, fetchTimeSheetPut, fetchTimeSheetRaw } from "../middleware/fetcher/timesheet";
import { fetchShiftEmp } from "../middleware/fetcher/shift";
import { FormDatePicker, FormTimePicker } from "../_start/layout/components/form-compoment";
import { allEvents, danger, IEvents, info, primary, success, warning } from "../interfaces/ICalendar";
const { confirm } = Modal;

const CalendarTime: React.FC = () => {
  const { setMenuSelectedKey } = useAppDataContext();
  const [open, setOpen] = useState(false);
  const [timeSheetData, setTimeSheetData] = useState<Array<TimeSheetInit>>([]);
  const [projectSelect, setProjectSelect] = useState<Array<DropDown>>();
  const [taskSelect, setTaskSelect] = useState<Array<DropDown>>();
  const [formTaskSelect, setFormTaskSelect] = useState<Array<DropDown>>();
  const [shiftEmp, setShiftEmp] = useState<IShift>();
  const [event, setEvent] = useState("");
  const [id, setId] = useState("");
  const [calendarEvent, setCalendarEvent] = useState<Array<IEvents>>([]);
  const [disable, setDisable] = useState<boolean>();
  const [isTaskDelete, setIsTaskDelete] = useState<boolean>();
  const fetchAPITimeSheetInit = async () => {
    const rsTimeSheetInit: any = await fetchTimeSheetInit();
    setTimeSheetData(rsTimeSheetInit.data);
  }

  const fetchAPIprojectSelectDropDown = async () => {
    const resProject: any = await fetchProjectSelect();
    setProjectSelect(resProject.data);
  }

  const [form] = Form.useForm();
  const onFinish = () => {
    const dt: TimeSheetForm = { ...form.getFieldsValue() };
    let title = "";
    if (event == "add") {
      title = "ต้องการบันทึกข้อมูล";
    } else {
      title = "ต้องการบันทึกข้อมูล";
    }
    confirm({
      title: `${title} ${dt.form_task}ใช่หรือไม่?`,
      async onOk() {
        try {
          const getDate = dayjs(dt.form_date).format('YYYY-MM-DD');
          const getStart = dayjs(dt.form_start).format('HH:mm');
          const getEnd = dayjs(dt.form_end).format('HH:mm');
          if (event == "add") {
            const tsCreate: TimeSheetCreate = {
              project: dt.form_project,
              task: dt.form_task,
              start: dayjs(`${getDate}T${getStart}`).toISOString(),
              end: dayjs(`${getDate}T${getEnd}`).toISOString(),
              date: dt.form_date
            }

            await fetchTimeSheetCreate(tsCreate);
          } else {
            const tsUpdate: TimeSheetUpdate = {
              id: id,
              date: dt.form_date,
              start: dayjs(`${getDate}T${getStart}`).toISOString(),
              end: dayjs(`${getDate}T${getEnd}`).toISOString(),
            }

            await fetchTimeSheetPut(tsUpdate);
          }

          const rsCalendar: any = await fetchCalendar();

          rsCalendar.data.map((calendar: any) => {

            let txtColor = "";
            let txtClass: string[] = [];
            switch (dayjs(calendar.date).get('day')) {
              case 0:
                txtColor = danger;
                txtClass = ["calendar-event", "calendar-event-danger"]
                break;
              case 1:
                txtColor = warning;
                txtClass = ["calendar-event", "calendar-event-warning"]
                break;
              case 2:
                txtColor = info;
                txtClass = ["calendar-event", "calendar-event-info"]
                break;
              case 3:
                txtColor = primary;
                txtClass = ["calendar-event", "calendar-event-primary"]
                break;
              case 4:
                txtColor = success;
                txtClass = ["calendar-event", "calendar-event-success"]
                break;
              case 5:
                txtColor = warning;
                txtClass = ["calendar-event", "calendar-event-warning"]
                break;
              case 6:
                txtColor = primary;
                txtClass = ["calendar-event", "calendar-event-primary"]
                break;
              default:
                break;
            }
            calendar.start = dayjs(calendar.start).format('YYYY-MM-DD');
            calendar.end = dayjs(calendar.end).format('YYYY-MM-DD');
            calendar.textColor = txtColor;
            calendar.className = txtClass;
            delete calendar.date
          });
          setCalendarEvent(rsCalendar.data);

          setOpen(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
        } catch (e) {
          return console.log('Oops errors!');
        }
      },
      onCancel() { },
    });
  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const onDateSelect = (e: any) => {
    setEvent("add");
    const dataCreate: TimeSheetForm = {
      form_project: "",
      form_task: "",
      form_start: "",
      form_end: "",
      form_date: dayjs(e, 'YYYY/MM/DD')
    }
    setId(e);
    form.setFieldsValue(dataCreate);
    setOpen(true);
  }

  const showDrawer = () => {
    setEvent("add");
    setOpen(true);
    setDisable(false);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const EditTask = async (e: string) => {
    setEvent("edit");
    setDisable(true);
    const rsTimeSheetRaw = await fetchTimeSheetRaw(e);
    const dataCreate: TimeSheetForm = {
      form_project: rsTimeSheetRaw.data.project,
      form_task: rsTimeSheetRaw.data.task,
      form_start: dayjs(rsTimeSheetRaw.data.start, 'YYYY/MM/DD HH:mm:ss').add(7, "hour"),
      form_end: dayjs(rsTimeSheetRaw.data.end, 'YYYY/MM/DD HH:mm:ss').add(7, "hour"),
      form_date: dayjs(rsTimeSheetRaw.data.date, 'YYYY/MM/DD')
    }
    setId(e);
    form.setFieldsValue(dataCreate);
    setOpen(true);
  };

  const DeleteRecord = (e: string) => {
    message.success(`ลบราย Project ${e} แล้ว`);
  };

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };


  const onSelectProjectChange = async (e: any) => {
    form.resetFields(["form_task"]);
    const resTask: any = await fetchTaskSelect(e);
    setFormTaskSelect(resTask.data);
  }

  const fetchAPIShiftEmp = async () => {
    const rsShiftEmp: any = await fetchShiftEmp();
    setShiftEmp(rsShiftEmp.data);
  }

  const fetchAPICalendar = async () => {
    const rsCalendar: any = await fetchCalendar();
    rsCalendar.data.map((calendar: any) => {

      let txtColor = "";
      let txtClass: string[] = [];
      switch (dayjs(calendar.date).get('day')) {
        case 0:
          txtColor = danger;
          txtClass = ["calendar-event", "calendar-event-danger"]
          break;
        case 1:
          txtColor = warning;
          txtClass = ["calendar-event", "calendar-event-warning"]
          break;
        case 2:
          txtColor = info;
          txtClass = ["calendar-event", "calendar-event-info"]
          break;
        case 3:
          txtColor = primary;
          txtClass = ["calendar-event", "calendar-event-primary"]
          break;
        case 4:
          txtColor = success;
          txtClass = ["calendar-event", "calendar-event-success"]
          break;
        case 5:
          txtColor = warning;
          txtClass = ["calendar-event", "calendar-event-warning"]
          break;
        case 6:
          txtColor = primary;
          txtClass = ["calendar-event", "calendar-event-primary"]
          break;
        default:
          break;
      }
      calendar.start = dayjs(calendar.start).format('YYYY-MM-DD');
      calendar.end = dayjs(calendar.end).format('YYYY-MM-DD');
      calendar.textColor = txtColor;
      calendar.className = txtClass;
      delete calendar.date
    });
    setCalendarEvent(rsCalendar.data);
  }

  const panelItem: any = [
    {
      key: '1',
      label: 'กะการทำงาน',
      style: panelStyle,
      children: (
        <>
          <Typography.Title level={5} style={{ margin: 0 }}>เวลา :</Typography.Title>
          <Typography.Paragraph>{dayjs(shiftEmp?.sTimeStart).format('HH:mm')} - {dayjs(shiftEmp?.sTimeEnd).format('HH:mm')}</Typography.Paragraph>
        </>
      ),
    },
    {
      key: '2',
      label: 'วัน',
      children: (
        <Flex wrap gap="small">
          {shiftEmp?.sSunday && (<Tag color="red">วันอาทิตย์</Tag>)}
          {shiftEmp?.sMonday && (<Tag color="gold">วันจันทร์</Tag>)}
          {shiftEmp?.sTuesday && (<Tag color="magenta">วันอังคาร</Tag>)}
          {shiftEmp?.sWednesday && (<Tag color="green">วันพุธ</Tag>)}
          {shiftEmp?.sThursday && (<Tag color="orange">วันพฤหัส</Tag>)}
          {shiftEmp?.sFriday && (<Tag color="blue">วันศุกร์</Tag>)}
          {shiftEmp?.sSaturday && (<Tag color="purple">วันเสาร์</Tag>)}
        </Flex>
      ),
      style: panelStyle,
    }
  ]

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => panelItem;

  useEffect(() => {
    setMenuSelectedKey("1_4");
    fetchAPIprojectSelectDropDown();
    fetchAPITimeSheetInit();
    fetchAPIShiftEmp();
    fetchAPICalendar();
  }, []);

  const selectChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <CalendarEvent setDrawer={showDrawer} dateEvent={onDateSelect} taskEvent={EditTask} events={calendarEvent} setIsTaskDelete={setIsTaskDelete} setDisable={setDisable} />
      <Drawer
        title="บันทึกเวลา"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ background: token.colorBgContainer }}
          items={getItems(panelStyle)}
        />
        <Divider />
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <FormSelect
            name="form_project"
            label="ระบุโครงการ"
            required={true}
            message="กรุณาระบุโครงการ"
            defaultValue={""}
            onChange={onSelectProjectChange}
            options={projectSelect}
            disabled={disable}
          />
          <FormSelect
            name="form_task"
            label="ระบุงาน"
            required={true}
            message="กรุณาระบุงาน"
            defaultValue={""}
            onChange={selectChange}
            options={formTaskSelect}
            disabled={disable}
          />
          <FormDatePicker name={"form_date"} label={"วันที่"} required={true} disabled={isTaskDelete} message="กรุณาระบุวันที่"/>
          <FormTimePicker
            name="form_start"
            label="เวลาเริ่มงาน"
            required={true}
            message="กรุณาระบุเวลาเริ่มงาน"
            disabled={isTaskDelete} />
          <FormTimePicker
            name="form_end"
            label="เวลาออกงาน"
            required={true}
            message="กรุณาระบุเวลาออกงาน"
            disabled={isTaskDelete} />
          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default CalendarTime;
