import React, { useEffect, useRef, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import {
  Card,
  Col,
  Row,
  Avatar,
  Table,
  Layout,
  Segmented,
  DatePicker,
  Dropdown,
  MenuProps,
  Select,
  Tag,
  Typography,
} from "antd";
import {
  ClockCircleOutlined,
  ClockCircleFilled,
  BoldOutlined,
  CreditCardOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import ApexCharts from "apexcharts";
const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const columns1 = [
    {
      title: "TEAM MEMBER",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "LAST ACTIVITY",
      dataIndex: "project",
      key: "project",
      render: (_: any, record: any) => (
        <>
          <p style={{ fontSize: 16, fontWeight: "bold" }}>{record.project}</p>
          <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 20 }}>
            <div>
              <Tag color="#2db7f5">{record.task}</Tag>
              <Text underline>{record.depart}</Text>
            </div>
            <div>
              <Tag icon={<ClockCircleOutlined />} color="default" style={{ fontSize: 14 }}>
                {record.duration}
              </Tag>
              <Tag icon={<CalendarOutlined />} color="default" style={{ fontSize: 14 }}>
                {record.tracking_date}
              </Tag>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "TOTAL TRACKED",
      dataIndex: "total_track_time",
      key: "total_track_time",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", height: "60px" }}>
          <Tag bordered={false} color="processing" style={{ fontSize: 16, alignSelf: "flex-end" }}>
            {record.total_track_time}
          </Tag>
        </div>
      ),
    },
  ];;

// Start table
const dataSource1 = [
    {
      key: "1",
      user: "Pornchai Akewaranugulsiri",
      project: "โครงการ1",
      task: "Task ทดสอบ1",
      depart: "IT",
      duration: "14:00",
      tracking_date: "14 ต.ค. 67",
      total_track_time: "28:00",
    },
    {
      key: "1",
      user: "Pornchai Akewaranugulsiri",
      project: "โครงการ2",
      task: "Task ทดสอบ2",
      depart: "IT",
      duration: "14:00",
      tracking_date: "14 ต.ค. 67",
      total_track_time: "28:00",
    },
  ];

const data1 = [
  {
    name: "Project A",
    data: [9, 10, 11, 12, 13, 14, 15],
  },
];

const data3 = [50];

const color1 = ["#F44336", "#E91E63"];
// End table
const SummaryReport: React.FC = () => {
  const [barData, setBarData] = useState<any>(data1);
  const [donutData, setDonutData] = useState<any>(data3);
  const [tableTitle, setTableTitle] = useState("รายการติดตามเวลา");
  const [tableColumn, setTableColumn] = useState(columns1);
  const [tableData, setTableData] = useState<any>(dataSource1);
  const [color, setColor] = useState(color1);
  const barChartRef = useRef(null);
  const donutChartRef = useRef(null);
  const { setMenuSelectedKey } = useAppDataContext();

  useEffect(() => {
    setMenuSelectedKey("2_0");
    if (barChartRef.current && donutChartRef.current) {
      const barChart = new ApexCharts(barChartRef.current, options);
      const donutChart = new ApexCharts(donutChartRef.current, donutOptions);

      barChart.render();
      donutChart.render();

      return () => {
        barChart.destroy();
        donutChart.destroy();
      };
    }
  }, [barData, donutData, color]);

  var options = {
    title: {
      text: "Summary Report",
      align: "center",
      margin: 20,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    series: barData,
    colors: color,
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: "end", // 'around', 'end'
        borderRadiusWhenStacked: "last", // 'all', 'last'
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: [
        "10/14/2024 GMT",
        "10/15/2024 GMT",
        "10/16/2024 GMT",
        "10/17/2024 GMT",
        "10/18/2024 GMT",
        "10/19/2024 GMT",
        "10/20/2024 GMT",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value + "h";
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  var donutOptions = {
    title: {
      text: "Summary Report",
      align: "center",
      margin: 20,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    series: donutData,
    colors: ["#147f63", "#1d258e", "#9C27B0"],
    chart: {
      type: "donut",
    },
    legend: {
      position: "top",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
          },
        },
      },
    },
  };

  return (
    <>
      <Header
        style={{
          padding: "0 20px",
          background: "#ffffff",
          border: "solid 1px #f0f0f0",
          borderRadius: 8,
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 15,
        }}
      >
        {/* <Select
          onChange={(e) => selectChange(e)}
          showSearch
          placeholder="Project"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { value: "1", label: "Project" },
            { value: "2", label: "Billabillty" },
          ]}
          style={{ marginRight: 20, width: 150 }}
        /> */}
        <RangePicker style={{ height: 33 }} />
      </Header>
     
      <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
        <Col className="gutter-row" span={18}>
          <div id="barChart" ref={barChartRef} style={{ border: "solid 1px #f0f0f0" }} />
        </Col>
        <Col className="gutter-row" span={6} style={{ display: "flex", alignItems: "center" }}>
          <div id="donutChart" ref={donutChartRef} style={{ border: "solid 1px #f0f0f0" }} />
        </Col>
      </Row>
      <Card title={tableTitle} style={{ marginTop: "24px" }}>
        <Table dataSource={tableData} columns={tableColumn} />
      </Card>
    </>
  );
};

export default SummaryReport;
