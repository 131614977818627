export interface ICalendar {
  setDrawer: (e: boolean) => void;
  dateEvent: (e: any) => void;
  taskEvent: (e: any) => void;
  events: any;
  setIsTaskDelete: any;
  setDisable: any;
}

export interface IEvents {
  id: string;
  title: string;
  description?: string;
  date?: string;
  start?: string;
  end?: string;
  allDay?: boolean;
  textColor?: string;
  className?: string[];
}

export const danger = "#ff3e1d";
export const primary = "#696cff";
export const warning = "#ffab00";
export const info = "#03c3ec";
export const success = "#71dd37";

export let allEvents: IEvents[] = [
  {
    id: "1",
    title: "event 1",
    description: "description for All Day Event",
    start: "2024-08-01",
    end: "2024-08-01",
    textColor: danger,
    className: ["calendar-event", "calendar-event-danger"],
  },
  {
    id: "2",
    title: "event 2",
    description: "description for Long Event",
    date: "2024-07-17",
    textColor: primary,
    className: ["calendar-event", "calendar-event-primary"],
  },
  {
    id: "3",
    title: "event 3",
    description: "description for Long Event",
    start: "2024-07-03",
    end: "2024-07-04",
    textColor: warning,
    className: ["calendar-event", "calendar-event-warning"],
  },
  {
    id: "4",
    title: "event 4",
    description: "description for Long Event",
    start: "2024-07-15",
    end: "2024-07-16",
    textColor: info,
    className: ["calendar-event", "calendar-event-info"],
  },
  {
    id: "5",
    title: "event 5",
    description: "description for Long Event",
    start: "2024-07-15",
    end: "2024-07-16",
    textColor: success,
    className: ["calendar-event", "calendar-event-success"],
  },
];

export let event2: IEvents[] = [
  {
    id: "1",
    title: "Gacha",
    description: "description for All Day Event",
    date: "2024-07-24",
    start: "2024-07-24T14:30:00",
    end: "2024-07-25T17:30:00",
    textColor: success,
    className: ["calendar-event", "calendar-event-success"],
  },
  {
    id: "2",
    title: "Gacha",
    description: "Gacha",
    date: "2024-07-25",
    start: "2024-07-25:08:00:00",
    end: "2024-07-26:12:00:00"
  },
  {
    id: "3",
    title: "Gacha",
    description: "Gacha",
    date: "2024-07-26",
    start: "2024-07-26:00:00:00",
    end: "2024-07-27:00:00:00"
  },
  {
    id: "4",
    title: "Gacha",
    description: "Gacha",
    date: "2024-07-27",
    start: "2024-07-27:00:00:00",
    end: "2024-07-26:10:00:00"
  }
]
