import { Button, Card, Col, Flex, Progress, Tag, Typography } from "antd";
import { ClockCircleOutlined, AntDesignOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { ITracker, mockTracker } from "../../../interfaces/ITimeTracker";
import { IObject } from "../../../interfaces/middleware/apiHandler";
import isEmpty from "is-empty";
import { requestStartTimeTrigger } from "../../../middleware/fetcher/timesheet";
import dayjs from "dayjs";
import { useAppDataContext } from "../core/AppProvider";
import { useMemo } from "react";

type IProps = {
  trackers: ITracker[];
  setTime: any;
  setTt: any;
  setTimeTracker: any;
  setCount: any;
  setProject: any;
  setProjectPublic: any;
  setTask: any;
  setDesc: any;
  setIsStart: any;
  isStart: boolean;
  setTrackerRun: any;
};

const TimeTrackerList: React.FC<IProps> = ({ trackers, setTime, setTt, setTimeTracker, setCount, setProject, setProjectPublic, setTask, setDesc, setIsStart, isStart, setTrackerRun }) => {
  const { permissions } = useAppDataContext();
  const permTimeTracker = useMemo(() => permissions?.timetracker?.permission_action || {}, [permissions]);
  const showTimer = (ms: number) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor(ms / 1000 / 60 / 60)
      .toString()
      .padStart(2, "0");

    return hour + ":" + minute + ":" + second;
  };

  const setTracker = (min: number, trackerCode: string, project: string, projectPublic:boolean, task: string, desc: string, task_code: string) => {
    const timeStr = showTimer(min);
    const popTrackers = trackers.filter((tracker: IObject) => tracker.task?.code !== task_code);

    setTime(timeStr);
    setTt(min);
    setTimeTracker(popTrackers);
    setCount(0);
    setProject(project);
    setProjectPublic(projectPublic);
    setTask(task);
    setDesc(desc);
  };

  const onClickStart = async (tracker: IObject) => {
    const startTrigger = await requestStartTimeTrigger({
      project: tracker.project?.code,
      task: tracker.task?.code,
      date: dayjs(),
      start: dayjs(),
      end: dayjs(),
    });
    if (!isEmpty(startTrigger?.data)) {
      setTrackerRun({
        id: startTrigger?.data?.id,
        date: dayjs(),
        start: dayjs(),
      });
      if (!isEmpty(tracker)) {
        setIsStart(true);
        setTracker(tracker.time_trace, startTrigger?.data?.id, tracker.project?.name, tracker.project?.is_public, tracker.task?.task_name, tracker.task?.description, tracker.task?.code);
      }
    }
  };
  const trackerList = trackers.map((tracker: IObject) => (
    <Col key={tracker.trackerCode} xs={24} md={12} lg={8}>
      <Card>
        {/* <Tag
          style={{ fontSize: "100%", marginBottom: "10px",overflow:"hidden" }}
          bordered={false}
          color={(tracker.project.is_public)?"green":"blue"}
        >
          {tracker.project?.name}
        </Tag> */}
        <Typography.Text underline>
          {tracker.project?.name}
        </Typography.Text>
        <Tag bordered={false} color={tracker.project.is_public ? "green" : "blue"} style={{marginLeft:5}}>
          {tracker.project.is_public ? "Public" : "Private"}
        </Tag>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {tracker.task?.task_name}
        </Typography.Title>
        <Typography.Paragraph>{tracker.task?.desc}</Typography.Paragraph>

        <Typography.Title level={5} style={{ margin: 0 }} type="secondary">
          <ClockCircleOutlined /> {showTimer(tracker.time_trace)} Hour
        </Typography.Title>
        <br />
        <Flex>
          {permTimeTracker?.edit && (
            <Button
              type="primary"
              block
              icon={<AntDesignOutlined />}
              disabled={(!tracker.is_trigger || isStart)}
              onClick={() => {
                onClickStart(tracker);
              }}
            >
              START
            </Button>
          )}
        </Flex>
      </Card>
    </Col>
  ));
  return <>{trackerList}</>;
};

export { TimeTrackerList };
