import { Table } from "ant-table-extensions";
import { Button, Drawer, Flex, Form, message, Space, Typography, Modal } from "antd";
import {
    columns,
    IRequestCreateRole,
    IRequestUpdateRole,
    IRolePermission,
    IRoles,
} from "../interfaces/IRole";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EEvent, EStatusColumn } from "../interfaces/ICenter";
import { FormTextArea } from "../_start/layout/components/form-compoment/FormTextArea";
import { FormSwitch } from "../_start/layout/components/form-compoment/FormSwitch";
import { fetchRolePermission, fetchRolePermissionCreate, fetchRolePermissionDelete, fetchRolePermissionEdit } from "../middleware/fetcher/role";
import { IObject } from "../interfaces/middleware/apiHandler";
import _ from "lodash";
const { confirm } = Modal;

const Roles: React.FC = () => {
    const [rolePermission, setRolePermission] = useState<Array<IRolePermission>>();
    const [statusValue, setStatusValue] = useState<string>(EStatusColumn.ACTIVE);
    const { setMenuSelectedKey, permissions } = useAppDataContext();
    const [open, setOpen] = useState(false);
	const [event, setEvent] = useState("");
	const [inputDisable, setInputDisable] = useState<boolean>();
    const permRole = useMemo(() => permissions?.role?.permission_action || {}, [permissions]);


    useEffect(() => {
        setMenuSelectedKey("3_6");
        getRolePermissionList();
      
    }, []);

    const getRolePermissionList = async () => {
        const rolePermissionList: any = await fetchRolePermission("all");
        setRolePermission(rolePermissionList.data);
    }
    const [form] = Form.useForm();

	// const DeleteRecord = async (e: string) => {
	// 	const empCode = e.split(" ")[0];
	// 	await deleteEmployee(empCode);
	// 	message.success(`ลบรายการพนักงาน ${e} แล้ว`);
	// 	getEmpList();
	// };
    const DeleteRecord = async (e: string) => {
			const roleCode = e.split(" ")[0];
			await fetchRolePermissionDelete(roleCode);
      message.success(`ลบ role ${e} แล้ว`);
			getRolePermissionList();
    };

    const showDrawer = () => {
		setEvent(EEvent.ADD);
		setInputDisable(false);
        setOpen(true);
    };

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    };
	const onFinish = () => {
		const dt: IRolePermission = { ...form.getFieldsValue() };

		if (event === 'edit') {
			confirm({
				title: `ต้องการบันทึกข้อมูลใช่หรือไม่`,
				async onOk() {
					try {
						const roleCode: string = dt.role_code;
						const dataUpdate: IRequestUpdateRole = {
							name: dt.role_name,
							status: statusValue
						}
						await fetchRolePermissionEdit(roleCode, dataUpdate);
						getRolePermissionList();
						setOpen(false);
						message.success("บันทึกข้อมูลสำเร็จ!");
						form.resetFields();
					} catch (err) {
						return console.log('Oops errors!');
					}
				},
				onCancel() { }
			})
		}
		if (event === 'add') {
			confirm({
				title: `ต้องการบันทึกข้อมูลใช่หรือไม่`,
				async onOk() {
					try {
						const dataCreate: IRequestCreateRole = {
							code: dt.role_code,
							name: dt.role_name,
							status: statusValue
						}
						await fetchRolePermissionCreate(dataCreate);
						getRolePermissionList();
						setOpen(false);
						message.success("บันทึกข้อมูลสำเร็จ!");
						form.resetFields();
					} catch (err) {
						return console.log('Oops errors!');
					}
				},
				onCancel() { }
			})
		}
	};

    const onFinishFailed = () => {
        message.error("บันทึกข้อมูลล้มเหลว!");
    };

	const editRolePermission = (e: any) => {
		setEvent("edit");
		setInputDisable(true);
		const ed: any = _.filter(rolePermission, (v, i) => v.role_code === e);
		const edt: IRolePermission | any = { ...ed[0] };
		form.setFieldsValue(edt);
		setOpen(true);
	}


    const selectChange = (value: string) => {
        setStatusValue(value);
        console.log(`selected ${value}`);
    };

    return (
        <>
            <Flex key="AddEmp" align="flex-end" gap="small" vertical>
                {permRole?.add && <Button type="primary" onClick={showDrawer}>
                    <PlusOutlined />
                    เพิ่ม Role
                </Button>}
            </Flex>
            <br />
            <Drawer
                title="Role"
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
					<Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                    <FormInput
                        name="role_code"
                        label="รหัสสิทธิ์"
                        required={true}
                        message="กรุณาระบุรหัสสิทธิ์"
						disabled={inputDisable}
                    />
                    <FormInput
                        name="role_name"
                        label="ชื่อสิทธิ์"
                        required={true}
                        message="กรุณาระบุชื่อสิทธิ์"
                    />
                    <FormSelect
                        name="สถานะ"
                        label="สถานะ"
                        required={true}
                        message="กรุณาเลือกสถานะ"
                        defaultValue={EStatusColumn.ACTIVE}
                        onChange={selectChange}
                        options={DropDown}
                    />
                    <Space>
                        <Button onClick={onClose}>ยกเลิก</Button>
                        <Button type="primary" htmlType="submit">
                            บันทึก
                        </Button>
                    </Space>
                </Form>
            </Drawer>

            <Table
                columns={columns(editRolePermission, DeleteRecord, permRole)}
                dataSource={rolePermission}
                searchable
            />
        </>
    );
};

export default Roles;