import { Popconfirm, Space, Tag } from "antd";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { TagStatus } from "./ICenter";

export interface IBranch {
  brn_code: string;
  brn_name: string;
  brn_desc?: string;
  brn_status: string;
  brn_created_by?: string;
  brn_createdAt?: Date;
  brn_updated_by?: string;
  brn_updatedAt?: Date;
}

export interface IBranchTblData {
  รหัสสาขา: string;
  สาขา: string;
  รายละเอียด: string;
  สถานะ: string;
}

export interface IRequestCreateBranch {
  brn_code: string;
  brn_name: string;
  brn_desc?: string;
  brn_status: string;
}

export interface IRequestUpdateBranch {
  brn_name: string;
  brn_desc?: string;
  brn_status: string;
}

// Data mockup
export let mockData: IBranch[] = [
  {
    brn_code: "BRN01",
    brn_name: "รามอินทรา",
    brn_desc: "รายละเอียดเพิ่มเติม",
    brn_status: "active",
  },
];

// Set columns for table
export const columns = (EditBranch: any, DeleteRecord: any, permBranch:any) => {
  return [
    {
      title: "รหัสสาขา",
      dataIndex: "brn_code",
      key: "brn_code",
    },
    {
      title: "สาขา",
      dataIndex: "brn_name",
      key: "brn_name",
    },
    {
      title: "รายละเอียด",
      dataIndex: "brn_desc",
      key: "brn_desc",
    },
    {
      title: "สถานะ",
      key: "brn_status",
      dataIndex: "brn_status",
      render: (_: any, record: any) =>
        TagStatus(record.brn_status, record.brn_code),
    },
    {
      title: "ดำเนินการ",
      key: "action_brn_code",
      render: (_: any, record: any) => (
        <Space size="middle">
          {permBranch?.edit && <EditOutlined
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => EditBranch(record.brn_code)}
          />}

          <Popconfirm
            onConfirm={() => DeleteRecord(record.brn_code, record.brn_name)}
            title="ยืนยันการลบข้อมูล"
            description="คุณต้องลบข้อมูลหรือไม่?"
          >
            {permBranch?.delete && <DeleteOutlined style={{ color: "red" }} />}
          </Popconfirm>

          <Link to={`/Department/${record.brn_code}/${record.brn_name}`} title="Department">
            {permBranch?.edit && <ApartmentOutlined />}
          </Link>
        </Space>
      ),
    },
  ];
};
