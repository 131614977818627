import React, { useMemo, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Button, Layout, Avatar, Space, Badge, Popover } from "antd";
import Cookies from "js-cookie";
import { useAppDataContext } from "../core/AppProvider";
import { requestLogout } from "../../../middleware/fetcher/auth";

const { Header } = Layout;

type Props = {
  collapsed: any;
  colorBgContainer: any;
  setCollapsed: any;
};

const AppHeader: React.FC<Props> = ({
  collapsed,
  colorBgContainer,
  setCollapsed,
}) => {
  const { user } = useAppDataContext();
  const [arrow, setArrow] = useState("Show");
  const handleLogout = async () => {
    // Remove login status from localStorage or session
    // Redirect to login page after logout
    Cookies.remove("access_token");
    await requestLogout();
    window.location.href = "/login"; // or use history.push('/login') if within React Router context
  };
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 2,
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: 0,
        background: "rgb(245, 245, 245)",
      }}
    >
      <Button
        type="text"
        size="large"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 32,
          height: 32,
          marginLeft: "24px"
        }}
      />

      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", textAlign: "right", paddingRight: "24px" }}
      >
        <Space wrap size={16}>
          <label>ยินดีต้อนรับ : {user.emp_first_name +' ' +user.emp_last_name}</label>

          <Popover
            placement="bottomRight"
            content={
              <div>
                <a onClick={handleLogout}>Logout</a>
              </div>
            }
            arrow={mergedArrow}
          >
            <Avatar style={{ backgroundColor: "#87d068", verticalAlign: 'middle' }} size="large" gap={1}>
              {(user.emp_first_name !== undefined)?user.emp_first_name.charAt(0):user.emp_first_name}
            </Avatar>
          </Popover>
        </Space>
      </Space>
    </Header>
  );
};

export { AppHeader };
