import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const requestLogin = (body: any) => {
  return requestHandler({
    method: "post",
    withCredentials: false,
    url: `${host}/auth/login`,
    data: body,
  });
};

export const requestProtected = () => {
  return requestHandler({
    method: "get",
    withCredentials: false,
    url: `${host}/auth/protected`,
    isAuthen: true
  });
}

export const requestLogout = () => {
  return requestHandler({
    method: "post",
    withCredentials: false,
    url: `${host}/auth/logout`,
    isAuthen: true
  })
}