import { bo } from "@fullcalendar/core/internal-common";
import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchTimeSheetInit = () => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/time-sheet/init`,
        isAuthen: true,
    });
};

export const fetchProjectSelect = () => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/project-access/select`,
        isAuthen: true,
    });
};

export const fetchTaskSelect = (param?: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/task/select/${param}`,
        isAuthen: true,
    });
};

export const fetchTimesheetFilter = (body: any) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/time-sheet/filter`,
        data: body,
        isAuthen: true,
    });
};

export const requestStartTimeTrigger = (body: IObject) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/time-sheet/time-trigger`,
        data: body,
        isAuthen: true,
    });
};

export const fetchTimeTrigger = () => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/time-sheet/task-trigger`,
        isAuthen: true,
        delay:1
    });
};

export const requestUpdateTimeSheet = (body: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/time-sheet`,
        isAuthen: true,
        data: body,
    });
};

export const fetchTimeSheetCreate = (body: IObject) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/time-sheet/time-sheet`,
        data: body,
        isAuthen: true,
    });
};

export const fetchTimeSheetRaw = (param?: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/time-sheet/raw/${param}`,
        isAuthen: true,
    });
};

export const fetchTimeSheetPut = (body: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/time-sheet`,
        isAuthen: true,
        data: body
    });
};

export const fetchCalendar = () => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/time-sheet/calendar`,
        isAuthen: true,
    });
};