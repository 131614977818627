import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchRolePermission = async (roleCode: string | undefined) => {
    return await requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/rolePerm/list/${roleCode}`,
        isAuthen: true
    })
}

export const fetchRolePermissionDelete = async (roleCode: string) => {
    return await requestHandler({
        method: "delete",
        withCredentials: false,
        url: `${host}/rolePerm/delete/${roleCode}`,
        isAuthen: true
    })
}

export const fetchRolePermissionEdit = async (roleCode: string, body: any) => {
    return await requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/rolePerm/edit/${roleCode}`,
        data: body,
        isAuthen: true
    })
}

export const fetchRolePermissionCreate = async (body: any) => {
    return await requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/rolePerm/create`,
        data: body,
        isAuthen: true
    })
}

export const fetchRolePermissionEditPermission = async (roleCode: string | undefined, body: any) => {
    return await requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/rolePerm/edit-permission/${roleCode}`,
        data: body,
        isAuthen: true
    })
}