import React, { useEffect, useMemo, useState } from "react";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { Button, Space, Drawer, Form, Input, message, Flex, Modal } from "antd";
import { columns, IDepartment, IDepartmentTblData, IRequestCreateDepartment, IRequestUpdateDepartment, mockData } from "../interfaces/IDepartment";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { FormSelect } from "../_start/layout/components/form-compoment/FormSelect";
import { DropDown, EStatusColumn } from "../interfaces/ICenter";
import { FormInput } from "../_start/layout/components/form-compoment/FormInput";
import { fetchDepartment, fetchDepartmentCreate, fetchDepartmentEdit } from "../middleware/fetcher/department";
import { fetchBranchDeptList } from "../middleware/fetcher/employee";
import { useParams } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { CSVLink } from "react-csv";
const { confirm } = Modal;

const Department: React.FC = () => {
  const { setMenuSelectedKey, permissions } = useAppDataContext();
  const [open, setOpen] = useState(false);
  const [deptData, setDeptData] = useState<Array<IDepartment>>([]);
  const [exportTblData, setExportTblData] = useState<Array<IDepartmentTblData>>([]);
  const [event, setEvent] = useState("");
  const [inputDisable, setInputDisable] = useState<boolean>();
  const [statusValue, setStatusValue] = useState<string>(EStatusColumn.ACTIVE);
  const { id, name } = useParams();
  const permDepartment = useMemo(() => permissions?.department?.permission_action || {}, [permissions]);
  useEffect(() => {
    setMenuSelectedKey("3_3");
    getDepartmentList();
    departTblData();
  }, []);

  const getDepartmentList = async () => {
    const departmentList: any = await fetchBranchDeptList();
    const getDepart = departmentList?.data.find((el: { brn_code: any; }) => el.brn_code == id);

    setDeptData(getDepart.department);
  }

  const departTblData = async () => {
    const departmentList: any = await fetchBranchDeptList();
    const getDepart = departmentList?.data.find((el: { brn_code: any; }) => el.brn_code == id);
    let tblData: IDepartmentTblData[] = [];
    let departTblData: IDepartmentTblData;

    getDepart.department.map((item: any) => {
      departTblData = {
        รหัสหน่วยงาน: item.dept_code,
        หน่วยงาน: item.dept_name,
        รายละเอียด: item.dept_desc,
        สถานะ: item.dept_status
      }
      tblData.push(departTblData);
    });
    setExportTblData(tblData);
  }

  const showDrawer = () => {
    setEvent("add");
    setInputDisable(false);
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const [form] = Form.useForm();

  const onFinish = async () => {
    const dt: IDepartment = { ...form.getFieldsValue() };
    let title = "";
    if (event == "add") {
      title = "ต้องการบันทึกข้อมูล";
    } else {
      title = "ต้องการบันทึกข้อมูล";
    }

    confirm({
      title: `${title} ${dt.dept_name}ใช่หรือไม่?`,
      async onOk() {
        try {
          const code: string = dt.dept_code;

          if (event == "add") {
            const dataCreate: IRequestCreateDepartment = {
              code: dt.dept_code,
              name: dt.dept_name,
              desc: dt.dept_desc,
              status: statusValue,
              brn_code: id
            }
            await fetchDepartmentCreate(dataCreate);
          } else {
            const dataEdit: IRequestUpdateDepartment = {
              name: dt.dept_name,
              desc: dt.dept_desc,
              status: dt.dept_status
            }
            await fetchDepartmentEdit(code, dataEdit);
          }

          const departmentList: any = await fetchBranchDeptList();
          const getDepart = departmentList?.data.find((el: { brn_code: any; }) => el.brn_code == id);
          setDeptData(getDepart.department);

          setOpen(false);
          message.success("บันทึกข้อมูลสำเร็จ!");
          form.resetFields();
        } catch (e) {
          return console.log('Oops errors!');
        }
      },
      onCancel() { },
    });


  };

  const onFinishFailed = () => {
    message.error("บันทึกข้อมูลล้มเหลว!");
  };

  const DeleteRecord = (e: string) => {
    message.success(e);
  };

  const EditDept = (e: string) => {
    setEvent("edit");
    setInputDisable(true);
    const ed: any = _.filter(deptData, (v, i) => v.dept_code === e);
    const edt: IDepartment = { ...ed[0] };
    form.setFieldsValue(edt);
    setOpen(true);
  };


  const selectChange = (value: string) => {
    setStatusValue(value);
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Space>
        <h3 className="m-0 me-16">รหัสสาขา : {id}</h3>
        <h3 className="m-0">สาขา : {name}</h3>
      </Space>
      <Flex key="AddBranch" align="flex-end" gap="small" vertical>
        {permDepartment?.add && <Button type="primary" onClick={showDrawer}>
          <PlusOutlined />
          เพิ่มหน่วยงาน
        </Button>}
      </Flex>
      <br />
      <Drawer
        title="ข้อมูลหน่วยงาน"
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <FormInput
            name="dept_code"
            label="รหัสหน่วยงาน"
            required={true}
            message="กรุณาระบุรหัสหน่วยงาน"
            disabled={inputDisable}
          />

          <FormInput
            name="dept_name"
            label="ชื่อหน่วยงาน"
            required={true}
            message="กรุณาระบุชื่อหน่วยงาน"
          />

          <Form.Item name="dept_desc" label="รายละเอียดเพิ่มเติม">
            <Input.TextArea rows={4} placeholder="รายละเอียดเพิ่มเติม" />
          </Form.Item>

          <FormSelect
            name="dept_status"
            label="สถานะ"
            required={false}
            message="กรุณาเลือกสถานะ"
            defaultValue={EStatusColumn.ACTIVE}
            onChange={selectChange}
            options={DropDown}
          />

          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form>
      </Drawer>

      <CSVLink filename={"Department.csv"} data={exportTblData} className="btn btn-primary" style={{position:"absolute"}}>
        บันทึกเป็น CSV
      </CSVLink>
      <Table
        rowKey="brn_code"
        columns={columns(EditDept, DeleteRecord, permDepartment)}
        dataSource={deptData}
        searchable
      />
    </>
  );
};

export default Department;
