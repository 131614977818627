import { DatePicker, Form, Input, TimePicker } from "antd";
import dayjs from 'dayjs';

type InputProps = {
  name: string;
  label?: string;
  required?: boolean;
  message?: string;
  type?: any;
  inputType?: string;
  prefix?: any;
  disabled?: boolean
};

const FormInput: React.FC<InputProps> = ({
  name,
  label,
  required,
  message,
  type = 'text',
  inputType,
  prefix,
  disabled = false
}: InputProps) => {
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: message,
          },
        ]}
      >
        <Input prefix={prefix} type={inputType} placeholder={label} disabled={disabled} />
      </Form.Item>
    </>
  );
};

type AddOnBeforeInputProps = {
  name: string;
  label: string;
  addonBefore: any;
};

const AddOnBeforeInput: React.FC<AddOnBeforeInputProps> = ({
  name,
  label,
  addonBefore,
}: AddOnBeforeInputProps) => {
  return (
    <>
      <Form.Item name={name} label={label}>
        <Input addonBefore={addonBefore} style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export { FormInput, AddOnBeforeInput };
