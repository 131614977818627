import { Form, Popconfirm, Select, Space } from "antd";
import { ITreeDepart } from "./IDepartment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { EStatusColumn, TagStatus } from "./ICenter";

export interface IEmployee {
  emp_code: string;
  role_code: string;
  shift_code?: string;
  emp_first_name: string;
  emp_last_name: string;
  emp_salary: number;
  emp_email: string;
  emp_phone: string | null;
  emp_username: string;
  emp_password: string;
  emp_role?: string;
  emp_status: string;
  emp_created_by?: string;
  emp_createdAt?: Date;
  emp_updated_by?: string;
  emp_updatedAt?: Date;
  dept_code: string;
  brn_code: string;
}

export interface IEmpTblData {
  รหัสสาขา : string;
  รหัสหน่วยงาน : string;
  รหัสพนักงาน : string;
  ชื่อนามสกุล : string;
  อีเมล์ : string;
  โทรศัพท์ : string;
  สถานะ : string;
}

export interface IRequestUpdateEmployee {
  first_name: string | null;
  last_name: string | null;
  salary: number | null;
  phone: string | null;
  status: string | undefined;
  role_code: string | null;
  dept_code: string | undefined;
  email: string | null;
  shift_code: string | undefined;
}

export interface IRequestCreateEmployee {
  code: string;
  first_name: string;
  last_name: string;
  dept_code: string | undefined;
  salary: number | null;
  email: string;
  phone: string | null;
  username: string;
  password: string;
  role_code: string;
  status: string | undefined;
  shift_code: string | undefined;
}

// export const selectTreeOption = [
//   {
//     label: <span>นครปฐม</span>,
//     // title: "manager",
//     options: [
//       { label: <span>IT</span>, value: "IT" },
//       { label: <span>HR</span>, value: "HR" },
//     ],
//   },
//   {
//     label: <span>กาฐจนบุรี</span>,
//     // title: "engineer",
//     options: [
//       { label: <span>PR</span>, value: "PR" },
//       { label: <span>MK</span>, value: "MK" },
//     ],
//   },
// ];

export function selectTreeBranchDept(branchDept: any) {
  let brnDept = [];
  for (let i = 0; i < branchDept.length; i++) {
    let dept = [];
    for (let j = 0; j < branchDept[i].department.length; j++) {
      if (branchDept[i].department[j].dept_status === EStatusColumn.ACTIVE) {
        dept.push(
          { label: <span>{branchDept[i].department[j].dept_name}</span>, value: branchDept[i].department[j].dept_code }
        )
      }
    }
    if (branchDept[i].brn_status === EStatusColumn.ACTIVE) {
      brnDept.push(
        {
          label: <span>{branchDept[i].brn_name}</span>,
          title: branchDept[i].brn_code,
          options: dept
        }
      )
    }
  }
  return brnDept;
}

export function selectTreeRolePerm(rolePerm: any) {
  let roleCodeArr = [];
  for (let i = 0; i < rolePerm.length; i++) {
    if (rolePerm[i].role_status === EStatusColumn.ACTIVE) {
      roleCodeArr.push(
        { label: <span>{rolePerm[i].role_name}</span>, value: rolePerm[i].role_code }
      )
    }
  }
  return roleCodeArr;
}

export const treeData: ITreeDepart[] = [
  {
    value: "parent 1",
    title: "parent 1",
    children: [
      {
        value: "leaf1",
        title: "leaf1",
      },
      {
        value: "leaf2",
        title: "leaf2",
      },
    ],
  },
  {
    value: "parent 2",
    title: "parent 2",
    children: [
      {
        value: "leaf3",
        title: "leaf3",
      },
      {
        value: "leaf4",
        title: "leaf4",
      },
    ],
  },
];

export const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select defaultValue="66" style={{ width: 70 }}>
      <Select.Option value="66">+66</Select.Option>
      <Select.Option value="86">+86</Select.Option>
      <Select.Option value="87">+87</Select.Option>
    </Select>
  </Form.Item>
);

// Set columns for table
export const columns = (EditEmp: any, DeleteRecord: any, permEmployee: any) => {
  return [
    {
      title: "รหัสสาขา",
      dataIndex: "brn_code",
      key: "brn_code",
    },
    {
      title: "รหัสหน่วยงาน",
      dataIndex: "dept_code",
      key: "dept_code",
    },
    {
      title: "รหัสพนักงาน",
      dataIndex: "emp_code",
      key: "emp_code",
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "emp_first_name",
      key: "emp_first_name",
      render: (_: any, record: any) => (
        <label>{`${record.emp_first_name} ${record.emp_last_name}`}</label>
      ),
    },
    {
      title: "อีเมล์",
      dataIndex: "emp_email",
      key: "emp_email",
    },
    {
      title: "โทรศัพท์",
      dataIndex: "emp_phone",
      key: "emp_phone",
    },
    {
      title: "สถานะ",
      key: "emp_status",
      dataIndex: "emp_status",
      render: (_: any, record: any) =>
        TagStatus(record.emp_status, record.emp_code),
    },
    {
      title: "ดำเนินการ",
      key: "action_emp_code",
      render: (_: any, record: any) => (
        <Space size="middle">
          {permEmployee?.edit && <EditOutlined
            style={{ cursor: "pointer", color: "blue"}}
            onClick={() => EditEmp(record)}
          />}

          <Popconfirm
            onConfirm={() =>
              DeleteRecord(record.emp_code + " " + record.emp_first_name + " " + record.emp_last_name)

            }
            title="ยืนยันการลบข้อมูล"
            description="คุณต้องลบข้อมูลหรือไม่?"
          >
            {permEmployee?.delete && <DeleteOutlined style={{ color: "red" }} />}
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
