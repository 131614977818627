import React from "react";
import { Form, Select } from "antd";
import { IObject } from "../../../../interfaces/middleware/apiHandler";

type SelectProps = {
    name: string;
    label: string;
    required: boolean;
    message: string;
    defaultValue?: string | string[];
    onChange?: any;
    options?: any;
    keySelect?: string;
    labelSelect?: string;
    disabled?: boolean;
};

const FormSelect: React.FC<SelectProps> = ({
    name,
    label,
    required,
    message,
    defaultValue,
    onChange,
    options,
    disabled
}: SelectProps) => {
    return (
        <>
            <Form.Item
                name={name}
                label={label}
                rules={[{ required: required, message: message }]}
                initialValue={defaultValue}
            >
                <Select
                    style={{ width: "100%" }}
                    onChange={onChange}
                    options={options}
                    disabled={disabled}
                />
            </Form.Item>
        </>
    );
};

const FormSelectTree: React.FC<SelectProps> = ({
    name,
    label,
    required,
    message,
    onChange,
    options,
    defaultValue,
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={[{ required: required, message: message }]}
        >
            <Select
                defaultValue={defaultValue}
                style={{ width: "100%" }}
                onChange={onChange}
                options={options}
            />
        </Form.Item>
    );
};

const FormSelectMultiTag: React.FC<SelectProps> = ({
    name,
    label,
    required,
    message,
    onChange,
    options,
    defaultValue,
    keySelect = "value",
    labelSelect = "label",
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={[{ required: required, message: message }]}
            initialValue={defaultValue}
        >
            <Select
                defaultValue={defaultValue}
                mode="tags"
                style={{ width: "100%" }}
                placeholder={label}
                onChange={onChange}
                options={options.map((option: IObject) => ({
                    value: option[keySelect],
                    label: option[labelSelect],
                }))}
            />
        </Form.Item>
    );
};

const SelectMultiTag: React.FC<SelectProps> = ({
    name,
    label,
    onChange,
    options,
    defaultValue,
}) => {
    return (
        <Form.Item name={name} label={label} initialValue={defaultValue}>
            <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder={label}
                options={options}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export { FormSelect, FormSelectTree, FormSelectMultiTag, SelectMultiTag };
