import { Button, Card, Checkbox, Col, Divider, Flex, List, Row, Segmented, Typography, Form, message, Modal } from "antd";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { useEffect, useState } from "react";
import { PlusOutlined, UndoOutlined, SaveOutlined } from "@ant-design/icons";
import { CardListCheckbox } from "../_start/layout/components/form-compoment/FormListCheckbox";
import { IObject } from "../interfaces/middleware/apiHandler";
import { fetchPermission } from "../middleware/fetcher/permission";
import { useParams } from "react-router-dom";
import { fetchRolePermission, fetchRolePermissionEditPermission } from "../middleware/fetcher/role";
import { IRolePermAct } from "../interfaces/IRole";
const { Title, Text, Paragraph } = Typography;
const { confirm } = Modal;

const Permission: React.FC = () => {
    const { setMenuSelectedKey } = useAppDataContext();
    const [open, setOpen] = useState(false);
    const [permissionList, setPermissionList] = useState<IObject>([]);
    const [form] = Form.useForm();
    const { role_code } = useParams();

    useEffect(() => {
        setMenuSelectedKey("3_6");
        fetchAPIRolePermission();
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };
    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = () => {
        message.error("บันทึกข้อมูลล้มเหลว!");
    };

    const fetchAPIRolePermission = async () => {
        const rsPermissionList = await fetchRolePermission(role_code);
        let temp = JSON.parse(rsPermissionList.data.role_perm_act);
        setPermissionList(temp);
    }

    const onFinish = async () => {
        const dt: IObject = { ...form.getFieldsValue() };
        confirm({
            title: `ต้องการบันทึกข้อมูลใช่หรือไม่?`,
            async onOk() {
                try {
                    const permissionDb = await fetchPermission("all");
                    let permissionDbData: IRolePermAct[] = permissionDb.data;
                    const nameKey = Object.keys(dt);
                    for (let i = 0; i < nameKey.length; i++) {
                        for(let j = 0; j < permissionDbData.length; j++) {
                            if(nameKey[i] === permissionDbData[j].permission_name) {
                                permissionDbData[j].permission_action = dt[nameKey[i]];
                            }
                        }
                    }
                    await fetchRolePermissionEditPermission(role_code, permissionDbData);
                    message.success("บันทึกข้อมูลสำเร็จ!");
                } catch (err) {
                    return console.log('Oops errors!');
                }
            }
        })
    };

    console.log(permissionList,1111);
    return (
        <>
            <Form layout="vertical" form={form} autoComplete="off" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Flex align="center" justify="space-between">
                    <Flex vertical>
                        <Title level={3}>Configuration</Title>
                        <Paragraph type="secondary">Total 12 permission you have procress</Paragraph>
                    </Flex>
                    <Flex gap="middle">
                        <Button onClick={onReset}>
                            <UndoOutlined />
                            Reset
                        </Button>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                            Save
                        </Button>
                    </Flex>
                </Flex>
                <Row gutter={[16, 16]} >
                    {permissionList.map((item: IObject) => {
                        return (
                            <>
                                <Col span={8}>
                                    <CardListCheckbox
                                        name={item?.permission_name}
                                        title={item?.permission_name}
                                        paragraph={item?.permission_desc}
                                        dataSource={[item?.permission_action]}
                                    />
                                </Col>
                            </>
                        )
                    }
                    )}
                </Row>
            </Form>
        </>
    )
}

export default Permission;