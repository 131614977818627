import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchEmployee = async (empCode:string) => {
    return await requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/employee/list/${empCode}`,
        isAuthen: true
    })
}

export const deleteEmployee = async (empCode: string) => {
    return await  requestHandler({
        method: "delete",
        withCredentials: false,
        url: `${host}/employee/delete/${empCode}`,
        isAuthen: true
    })
}

export const fetchEmployeeEdit = (params: string, body: any) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/employee/edit/${params}`,
        data: body,
        isAuthen: true
    })
}

export const fetchEmployeeCreate = (body: any) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/employee/create`,
        data: body,
        isAuthen: true
    })
}

export const fetchBranchDeptList = async () => {
    return await requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/branch/department`,
        isAuthen: true
    })
}