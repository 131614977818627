import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Image,
  Alert,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { requestLogin } from "../middleware/fetcher/auth";
import { pageRoute } from "../constants/page";
import axios from "axios";
import Cookies from "js-cookie";

const Login: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [notice, setNotice] = useState("");

  const handleLogin = async () => {
    // Simulate login logic (replace with actual authentication)
    const data = {
      username: username,
      password: password,
    };
    const chkLogin: any = await requestLogin(data);
    console.log("=====> chkLogin", chkLogin);

    if (chkLogin?.status) {
      setVisible(true);
      setNotice(chkLogin?.message);
    } else {
      setVisible(true);
      setNotice(chkLogin?.message);
      Cookies.set("access_token", chkLogin?.data?.token);
      navigate(pageRoute.home);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout
        style={{
          backgroundImage: `url(${"/img-1-1.jpg"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
        }}
      >
        <Flex style={{ width: "100%", height: "100vh" }} justify="center" align="center">
          <Col>
            <Card style={{ boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)" }}>
              <Flex justify="center" align="center">
                <Image
                  className='className="demo-logo-vertical"'
                  src="/logo.png"
                  preview={false}
                  width={250}
                />
              </Flex>
              {visible && (
                <Alert
                  message={notice}
                  type="error"
                  showIcon
                  closable
                  style={{ marginBottom: "20px" }}
                  afterClose={handleClose}
                />
              )}
              <Form
                name="loginForm"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: "Please input your Username!" }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please input your Password!" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Flex justify="space-between">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <a className="login-form-forgot" href="">
                      Forgot password
                    </a>
                  </Flex>
                </Form.Item>

                <Form.Item>
                  <Flex justify="center">
                    <Button type="primary" htmlType="submit" className="login-form-button" block>
                      Log in
                    </Button>
                  </Flex>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Flex>
      </Layout>
    </Layout>
  );
};

export default Login;
