import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppProvider from "./_start/layout/core/AppProvider";
import { PrivateRoutes } from "./route/PrivateRoutes";
import { ConfigProvider } from "antd";
import "./index.css";

import { PublicRoutes } from "./route/PublicRoutes";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ConfigProvider>
        <AppProvider>
          <Routes>
            {PublicRoutes}
            {PrivateRoutes}
            <Route path="/*" element={<Navigate to="/login" replace />} />
          </Routes>
        </AppProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
