import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchBranch = (params?: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/branch/list/${params}`,
        isAuthen: true
    });
}

export const fetchBranchDelete = (params: string) => {
    return requestHandler({
        method: "delete",
        withCredentials: false,
        url: `${host}/branch/delete/${params}`,
        isAuthen: true
    })
}

export const fetchBranchEdit = (params: string, body: any) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        url: `${host}/branch/edit/${params}`,
        data: body,
        isAuthen: true
    })
}

export const fetchBranchCreate = (body: any) => {
    return requestHandler({
        method: "post",
        withCredentials: false,
        url: `${host}/branch/create`,
        data: body,
        isAuthen: true
    });
}