import { requestHandler } from "../api_handler"
const host = process.env.REACT_APP_APIURL;

export const fetchPermission = async (roleCode: string | undefined) => {
    return await requestHandler({
        method: "get",
        withCredentials: false,
        url: `${host}/permission/list/${roleCode}`,
        isAuthen: true
    })
}