import { Link } from "react-router-dom";
import { EStatusColumn, TagStatus } from "./ICenter";
import { Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { title } from "process";
import { IObject } from "./middleware/apiHandler";
const { Text } = Typography;

export interface IShift {
  sCode: string;
  sName: string;
  sDesc: string | null;
  sStatus: EStatusColumn;
  sTimeStart: Date;
  sTimeEnd: Date;
  sBreak1Start: Date;
  sBreak1End: Date;
  sBreak2Start: Date | null;
  sBreak2End: Date | null;
  sBreak3Start: Date | null;
  sBreak3End: Date | null;
  sSunday: boolean;
  sMonday: boolean;
  sTuesday: boolean;
  sWednesday: boolean;
  sThursday: boolean;
  sFriday: boolean;
  sSaturday: boolean;
}

export interface IShiftTblData {
  SHIFT_NAME: string;
  DESCRIPTION: string;
  TIME: string;
  BREAK: string;
  HOUR: string;
  DAYS: string;
  STATUS: string;
}
// Set columns
// Set columns for table
export const ShiftColumns = (EditTask: any, DeleteRecord: any, permShift: IObject) => {
  return [
    {
      title: "ชื่อกะการเข้างาน",
      key: "sName",
      dataIndex: "sName",
      render: (_: any, record: any) => (
        <>
          {/* <Link to={`/Task/${record.sCode}`} title="Task"> */}
          <Text underline>{record.sName}</Text>
          <br />
          <Text type="secondary">{record.sDesc}</Text>
          {/* </Link> */}
        </>
      ),
    },
    {
      title: "เวลาเข้างาน",
      render: (_: any, record: any) => `${record.sTimeStart.format("HH:mm")} - ${record.sTimeEnd.format("HH:mm")}`,
    },
    {
      title: "เวลาพัก",
      render: (_: any, record: any) => {
        const break1Time = record.sBreak1Start && record.sBreak1End ? `${record.sBreak1Start.format("HH:mm")} - ${record.sBreak1End.format("HH:mm")}` : "";

        const break2Time = record.sBreak2Start && record.sBreak2End ? `${record.sBreak2Start.format("HH:mm")} - ${record.sBreak2End.format("HH:mm")}` : "";

        const break3Time = record.sBreak3Start && record.sBreak3End ? `${record.sBreak3Start.format("HH:mm")} - ${record.sBreak3End.format("HH:mm")}` : "";
        return (
          <>
            {break1Time}
            <br />
            {break2Time}
            <br />
            {break3Time}
          </>
        );
      },
    },
    {
      title: "ชั่วโมงการทำงาน",
      render: (_: any, record: any) => {
        let a: any = new Date(record.sTimeStart);
        let b: any = new Date(record.sTimeEnd);
        return Math.abs(b - a) / 36e5 + " Hr.";
      },
    },
    {
      title: "วัน",
      render: (_: any, record: any) => (
        <>
          {record.sSunday === true ? <Tag color="red">Sunday</Tag> : ""}
          {record.sMonday === true ? <Tag color="gold">Monday</Tag> : ""}
          {record.sTuesday === true ? <Tag color="magenta">Tuesday</Tag> : ""}
          {record.sWednesday === true ? <Tag color="green">Wednesday</Tag> : ""}
          {record.sThursday === true ? <Tag color="orange">Thursday</Tag> : ""}
          {record.sFriday === true ? <Tag color="blue">Friday</Tag> : ""}
          {record.sSaturday === true ? <Tag color="purple">Saturday</Tag> : ""}
        </>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "sStatus",
      key: "sStatus",
      render: (_: any, record: any) => TagStatus(record.sStatus, record.sCode),
    },
    {
      title: "ดำเนินการ",
      key: "task_code",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            {permShift?.edit && <EditOutlined style={{ cursor: "pointer", color: "blue" }} onClick={() => EditTask(record)} />}
            {permShift?.delete && (
              <Popconfirm onConfirm={() => DeleteRecord(record.sCode)} title="ยืนยันการลบข้อมูล" description="คุณต้องลบข้อมูลหรือไม่?">
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};

// Data mockup
export const mocShift: IShift[] = [
  {
    sCode: "S001",
    sName: "Human Resources Assistant",
    sDesc: "A simple shift for everyone",
    sStatus: EStatusColumn.INACTIVE,
    sTimeStart: new Date("01-01-1970 08:00:00"),
    sTimeEnd: new Date("01-01-1970 12:00:00"),
    sBreak1Start: new Date("01-01-1970 12:00:00"),
    sBreak1End: new Date("01-01-1970 13:00:00"),
    sBreak2Start: new Date("01-01-1970 14:00:00"),
    sBreak2End: new Date("01-01-1970 15:00:00"),
    sBreak3Start: null,
    sBreak3End: null,
    sSunday: true,
    sMonday: true,
    sTuesday: true,
    sWednesday: true,
    sThursday: true,
    sFriday: true,
    sSaturday: true,
  },
];
