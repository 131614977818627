import { Form, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

type InputProps = {
  name: string;
  label: string;
  required?: boolean;
  message?: string;
  inputType?: string;
  prefix?: any;
  defaultValue?: any;
};

const FormSwitch: React.FC<InputProps> = ({
  name,
  label,
  required,
  message,
  defaultValue,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: message,
        },
      ]}
      initialValue={defaultValue || false}
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
  );
};

export { FormSwitch };
