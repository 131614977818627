import { IObject } from "../../interfaces/middleware/apiHandler";
import { requestHandler } from "../api_handler";
const host = process.env.REACT_APP_APIURL;

export const fetchProjectAssignEmp = (projectCode: string) => {
    return requestHandler({
        method: "get",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project-access/get-assignee/${projectCode}`,
    });
};

export const putProjectAssign = (data: IObject) => {
    return requestHandler({
        method: "put",
        withCredentials: false,
        isAuthen: true,
        url: `${host}/project-access/assign`,
        data,
    });
};
