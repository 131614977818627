import Cookies from "js-cookie";
import { IObject, OptionAPI } from "../../interfaces/middleware/apiHandler";
import axios from "axios";
import { getErrorMessage, isServiceError } from "./apiHandler";

const defaultOptions: OptionAPI = {
  useMock: false,
  delay: 0,
  jsonMock: "",
  method: "get",
  url: "",
  params: {},
  data: {},
  timeout: 30000, //ms
  headers: {},
  withCredentials: true,
  ssr: false,
  isServerSide: false,
  isAuthen: false,
};

export const requestHandler = (userOptions: OptionAPI): Promise<IObject> => {
  let options = { ...defaultOptions, ...userOptions };
  const cookie = Cookies.get("access_token");
  //check ต้องการ Token หรือไม่
  if (userOptions?.isAuthen) {
    options = {
      ...options,
      headers: {
        ...options?.headers,
        "Content-Type": "application/json",
        Authorization: "bearer " + cookie,
      },
    };
  }
  return new Promise((resolve) => {
    setTimeout(async () => {
      resolve(fetcher(options));
    }, (options.delay || 0) * 1000);
  });
};

const fetcher = async (options: OptionAPI) => {
  let res;
  try {
    res = await axios(options);
    if (isServiceError(res)) {
      return getErrorMessage(res);
    }

    if (res && res?.headers["set-cookie"]) {
      const headerCookie = res.headers["set-cookie"];

      return { data: res.data.data, headerCookie };
    }
    if (res?.data?.type) {
      const a = { data: res?.data, res };
      return a;
    } else {
      return res.data;
    }
  } catch (error: any) {
    let resultError: IObject = {
      message:
        process.env.NODE_ENV === "development"
          ? "Something wrong please check API"
          : "Something wrong",
      status: error?.status || 500,
    };
    if (error?.response?.data) {
      let onlyError = error?.response?.data;
      if (onlyError?.error?.message) {
        resultError.message = onlyError?.error?.message;
      }
    }

    // let resultError: IObject = {};
    // if (error?.response?.data) {
    //    resultError = {
    //     message:error?.response?.data.message,
    //     status: error?.response?.data.status,
    //   };
    // }
    return { ...resultError };
  }
};
