import { Response } from "../../interfaces/middleware/apiHandler";

export const isServiceError = (res: Response) => {
  if (res === undefined) return true;
  if (res.status !== 200 && res.status !== 499) return true;
  if (res.status === 200 && res.data.success === false) return true;
  if (res?.data?.error) return true;
  if (res?.data?.fault) return true;
  if (res?.data?.IsError) return true;
  return false;
};

const errorMessage = (res: Response) => {
  if (res.status === 200 && !res.data.success) return "Error message 200";
  if (res.status === 400) return "Error message 400";
  if (res.status === 401) return "Error message 401";
  if (res.status === 403) return "Error message 403";
  if (res.status === 404) return "Error message 404";
  if (res.status === 500) return `Internal Server Error`;

  return res.status;
};

export const getErrorMessage = (res: Response) => {
  if (res) {
    const { data } = res;

    return {
      error: {
        type: "ERROR",
        status: res.status,
        statusText: errorMessage(res),
        message: data.message,
        success: data.success,
      },
    };
  } else {
    return {
      error: {
        type: "ERROR",
        code: "unknown",
      },
    };
  }
};
