import { useEffect, useState } from "react";
import { Button, Card, Col, Flex, Tag, Typography, Row, Form, message, Divider } from "antd";
import { SearchOutlined, StopOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useAppDataContext } from "../_start/layout/core/AppProvider";
import { ISearchTracker, ITracker } from "../interfaces/ITimeTracker";
import { TimeTrackerList } from "../_start/layout/components/TimeTrackerList";
import {
  fetchProjectSelect,
  fetchTaskSelect,
  fetchTimeTrigger,
  requestUpdateTimeSheet,
} from "../middleware/fetcher/timesheet";
import isEmpty from "is-empty";
import { IObject } from "../interfaces/middleware/apiHandler";
import dayjs from "dayjs";
import { FormSelect } from "../_start/layout/components/form-compoment";
import { DropDown } from "../interfaces/ICenter";

const TimeTracker: React.FC = () => {
  const { setMenuSelectedKey, isMobile } = useAppDataContext();

  //#region Timer
  const [timeTracker, setTimeTracker] = useState<Array<ITracker>>([]);
  const [projectSelect, setProjectSelect] = useState<Array<DropDown>>();
  const [taskSelect, setTaskSelect] = useState<Array<DropDown>>();
  const [isStart, setIsStart] = useState(false);
  const [count, setCount] = useState(0);
  const [time, setTime] = useState("00:00:00");
  const [tt, setTt] = useState(0);
  const [project, setProject] = useState<string>();
  const [projectPublic, setProjectPublic] = useState<string>();
  const [task, setTask] = useState<string>();
  const [desc, setDesc] = useState<string>();
  const [trackerRun, setTrackerRun] = useState<IObject>();
  const [formSearch] = Form.useForm();

  useEffect(() => {
    fetchAPIprojectSelectDropDown();
  }, []);

  const onSearch = async () => {
    const dt: ISearchTracker = { ...formSearch.getFieldsValue() };
    const filter: ISearchTracker = {
      project: dt.project,
      task: dt.task,
    };
    let timeTracker: any = await fetchTimeTrigger();
    let filterTimeTracker: any = [];
    if (filter.project === undefined && filter.task === undefined) {
      filterTimeTracker = timeTracker?.data;
    }
    if (filter.project !== undefined && filter.task === undefined) {
      timeTracker.data?.map((tracker: any) => {
        if (filter.project === tracker.project.code) {
          filterTimeTracker.push(tracker);
        }
      });
    }
    if (filter.project !== undefined && filter.task !== undefined) {
      timeTracker.data?.map((tracker: any) => {
        if (filter.project === tracker.project.code) {
          if (filter.task === tracker.task.code) {
            filterTimeTracker.push(tracker);
          }
        }
      });
    }
    setTimeTracker(filterTimeTracker);
  };

  const onProjectChange = async (e: any) => {
    formSearch.resetFields(["task"]);
    const resTask: any = await fetchTaskSelect(e);
    setTaskSelect(resTask.data);
  };
  const fetchAPIprojectSelectDropDown = async () => {
    const resProject: any = await fetchProjectSelect();
    setProjectSelect(resProject.data);
  };

  const onSearhFailed = () => {
    message.error("ไม่สามารถค้นหาข้อมูลได้!");
  };
  const initTime: any = new Date();

  const showTimer = (ms: number, tc: number) => {
    let msTotal = ms + tc;

    const second = Math.floor((msTotal / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((msTotal / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor(msTotal / 1000 / 60 / 60)
      .toString()
      .padStart(2, "0");
    setTime(hour + ":" + minute + ":" + second);
  };

  const clearTime = () => {
    setTime("00:00:00");
    setCount(0);
  };
  //#endregion Timer

  const fetchAPI = async () => {
    const trackerTemp = await fetchTimeTrigger();
    if (!isEmpty(trackerTemp?.data)) {
      setTimeTracker(trackerTemp?.data);
    }
  };

  useEffect(() => {
    fetchAPI();

    return () => {};
  }, []);

  useEffect(() => {
    setMenuSelectedKey("1_3");
    if (!isStart) {
      return;
    }

    var id = setInterval(() => {
      let getDate: any = new Date();
      let left = count + (getDate - initTime);
      setCount(left);
      showTimer(left, tt);
      if (left <= 0) {
        setTime("00:00:00:00");
        clearInterval(id);
      }
    }, 1000);
    return () => clearInterval(id);
  }, [isStart, tt]);

  const stopTracker = async () => {
    if (!isEmpty(trackerRun)) {
      const stop = await requestUpdateTimeSheet({
        id: trackerRun?.id,
        date: trackerRun?.date,
        start: trackerRun?.start,
        end: dayjs(),
      });
      if (!isEmpty(stop?.data)) {
        setIsStart(false);
        clearTime();
        setProject("");
        setTask("");
        setDesc("");
        await fetchAPI();
      }
    }
  };
  return (
    <>
      {isMobile && (
        <Typography.Text type="secondary" style={{ float: "right" }}>
          {" "}
          <VideoCameraOutlined /> ติดตามเวลา
        </Typography.Text>
      )}
      <Typography.Title level={3}>ค้นหาโดยระบุตัวกรอง</Typography.Title>
      <Form
        layout="vertical"
        form={formSearch}
        onFinish={onSearch}
        onFinishFailed={onSearhFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={24} md={12} className="pe-8px-filter pe-0px-filter">
            <FormSelect
              name="project"
              label="ระบุโครงการ"
              required={false}
              message={""}
              options={projectSelect}
              onChange={onProjectChange}
            />
          </Col>
          <Col xs={24} md={12} className="ps-8px-filter ps-0px-filter">
            <FormSelect
              name="task"
              label="ระบุงาน"
              required={false}
              message={""}
              options={taskSelect}
            />
          </Col>
        </Row>
        <Flex align="center" justify="center" style={{ marginBottom: "24px" }}>
          <Button className="w-100-percent" type="primary" htmlType="submit">
            <SearchOutlined /> ค้นหา
          </Button>
        </Flex>
      </Form>
      {/* <Divider/> */}
      <Row gutter={[16, 16]}>
        {isStart && (
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <Typography.Text
                    underline
                    style={{ color: projectPublic ? "#237804" : "#0958d9" }}
                  >
                    {project}
                  </Typography.Text>
                  <Tag
                    bordered={false}
                    color={projectPublic ? "green" : "blue"}
                    style={{ marginLeft: 5 }}
                  >
                    {projectPublic ? "Public" : "Private"}
                  </Tag>

                  <Typography.Title level={5} style={{ margin: 0 }}>
                    {task}
                  </Typography.Title>
                  <Typography.Paragraph>{desc}</Typography.Paragraph>
                </Col>
                <Col md={12}>
                  <Flex justify="space-evenly" align="center">
                    <Typography.Title level={1} style={{ width: "200px",marginTop: "20px" }}>
                      {time}
                    </Typography.Title>
                    <Flex vertical gap={"small"}>
                      <Button
                        type="primary"
                        danger
                        icon={<StopOutlined />}
                        onClick={() => stopTracker()}
                      >
                        Stop
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        <TimeTrackerList
          trackers={timeTracker}
          setTime={setTime}
          setTt={setTt}
          setTimeTracker={setTimeTracker}
          setCount={setCount}
          setProject={setProject}
          setProjectPublic={setProjectPublic}
          setTask={setTask}
          setDesc={setDesc}
          setIsStart={setIsStart}
          isStart={isStart}
          setTrackerRun={setTrackerRun}
        />
      </Row>
    </>
  );
};

export default TimeTracker;
