import { Typography } from "antd";
import { TagImStatus } from "./ICenter";

export interface IEmployee {
  emp_code: string;
  emp_name: string;
  emp_salary: number;
  branch: string;
  department: string;
  imp_status: boolean;
}

export interface IImportTblData {
  รหัสพนักงาน: string;
  ชื่อ_นามสกุล: string;
  รหัสแผนก: string;
  เงินเดือน: string;
  สถานะ: string;
}
// Data mockup
export let mockData: IEmployee[] = [
  {
    emp_code: "EMP01",
    emp_name: "Bailie Coulman",
    emp_salary: 10000,
    branch: "รามอินทรา",
    department: "IT",
    imp_status: true,
  },
  {
    emp_code: "EMP02",
    emp_name: "Jhone Wick",
    emp_salary: 900000,
    branch: "นครปฐม",
    department: "HR",
    imp_status: false,
  },
];

// Set columns for table
export const columns = () => {
  return [
    {
      title: "รหัสพนักงาน",
      dataIndex: "imp_emp_code",
      key: "imp_emp_code",
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "imp_emp_first_name",
      key: "imp_emp_first_name",
      render: (_: any, record: any) => (
        <Typography.Text>
          {`${record.imp_emp_first_name || ""} ${
            record.imp_emp_last_name || ""
          }`}
        </Typography.Text>
      ),
    },
    {
      title: "รหัสแผนก",
      dataIndex: "imp_dept_code",
      key: "imp_dept_code",
    },
    {
      title: "เงินเดือน",
      dataIndex: "imp_emp_salary",
      key: "imp_emp_salary",
    },
    {
      title: "สถานะ",
      key: "imp_status",
      dataIndex: "imp_status",
      render: (_: any, record: any) =>
        TagImStatus(record.imp_status, record.emp_code),
    },
  ];
};
